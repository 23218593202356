import { ClockIcon, PlayIcon, StopIcon } from 'assets/svgs'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateToHoursAndMinutes } from 'utils/dateUtils'
import './TransferTimer.css'

interface Props {
	startHour: Date
	endHour: Date | null
}

const TransferTimer: FC<Props> = (props) => {
	const { startHour, endHour } = props
	const [differenceTime, setDifferenceTime] = useState<string>('')

	const { t } = useTranslation()

	const getDifTime = (start: Date, end: Date | null) => {
		if (!end) return ''

		const dif = end.getTime() - start.getTime()
		const seconds = Math.floor(dif / 1000)
		const minutes = Math.floor(seconds / 60)
		const hours = Math.floor(minutes / 60)
		const days = Math.floor(hours / 24)

		let result = ''
		if (days > 0) result += `${days}d `
		if (hours % 24 > 0) result += `${hours % 24}h `
		if (minutes % 60 > 0) result += `${minutes % 60}m `
		if (seconds % 60 > 0) result += `${seconds % 60}s`

		return result.trim()
	}

	useEffect(() => {
		const time = getDifTime(startHour, endHour)
		setDifferenceTime(time)
	}, [startHour, endHour])

	return (
		<div className='transfer-timer'>
			<header className='transfer-timer-header'>
				{endHour ? (
					<div className='transfer-timer-header-wrapper'>
						<StopIcon />
						{t('transfer.transfer_finished')}
					</div>
				) : (
					<div className='transfer-timer-header-wrapper'>
						<PlayIcon />
						{t('transfer.transfer_started')}
					</div>
				)}
				{differenceTime ? (
					<div className='transfer-timer-header-wrapper'>
						<div className='transfer-time-header-clock'>
							<ClockIcon />
						</div>
						<span className='body2 transfer-timer-header-time'>{differenceTime}</span>
					</div>
				) : null}
			</header>
			<div className='transfer-timer-content'>
				<div className='transfer-timer-content-item'>
					<span className='body2 transfer-timer-content-item-label'>{t('transfer.start')}</span>
					<h3 className='transfer-timer-content-item-value'>{dateToHoursAndMinutes(startHour)}</h3>
				</div>
				<div className='transfer-timer-content-item'>
					<span className='body2 transfer-timer-content-item-label'>{t('transfer.end')}</span>
					<h3 className='transfer-timer-content-item-value'>
						{endHour ? dateToHoursAndMinutes(endHour) : '--'}
					</h3>
				</div>
			</div>
		</div>
	)
}

export default TransferTimer
