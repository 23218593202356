import DotsThreeVertical from 'assets/svgs/DotsThreeVertical'
import { NO_VALUE } from 'constants/common'
import useWorkLogsUtils from 'hooks/useWorkLogsUtils'
import { WorkLogItem } from 'models/WorkLog'
import { emptyString } from 'utils/common'
import './WorkLogTableItem.css'

interface Props {
	item: WorkLogItem
	onDotsClick: () => void
	onClick: () => void
	showName?: boolean
}

const WorkLogTableItem = ({ item, onDotsClick, onClick, showName = false }: Props) => {
	const { formatMinutesToHoursAndMinutes, calculateTotalMinutes } = useWorkLogsUtils()

	const parseTimeRange = (timeRange: string) => {
		const parts = timeRange.split('--')
		return {
			start: parts[0].trim(),
			end: parts[1] ? parts[1].trim() : emptyString
		}
	}

	const parsedTimeRanges = item.time.map(parseTimeRange)
	const hasIncompleteTimeRange = parsedTimeRanges.some(
		(range) => !range.end || range.end === emptyString
	)

	const totalMinutes = calculateTotalMinutes(parsedTimeRanges)
	const totalHoursFormatted = formatMinutesToHoursAndMinutes(totalMinutes)

	const renderTimeBlock = (timeRange: string, index: number) => {
		const { start, end } = parseTimeRange(timeRange)
		return (
			<div key={index} className='time-block-row'>
				<div className='work-log-column start-time-column'>
					<span className='time-value'>{start}</span>
				</div>
				<div className='work-log-column separator-column'>
					<span className='time-separator'>{NO_VALUE}</span>
				</div>
				<div className='work-log-column end-time-column'>
					<span className='time-value'>{end}</span>
				</div>
			</div>
		)
	}

	return (
		<div onClick={onClick} className='work-log-row'>
			<div className='work-log-column day-column'>
				{showName ? (
					<>
						<div className='day-name'>
							{`${item.userName.slice(0, 3)}. ${item.userLastName.slice(0, 3)}.`}
						</div>
						<div className='day-date'>
							{item.day} {item.formattedDate}
						</div>
					</>
				) : (
					<>
						<div className='day-name'>{item.day}</div>
						<div className='day-date'>{item.formattedDate}</div>
					</>
				)}
			</div>

			<div className='time-columns-container'>
				{item.time.map((timeRange, index) => renderTimeBlock(timeRange, index))}
			</div>

			<div className='work-log-column total-column'>
				<span className={`${hasIncompleteTimeRange ? 'no-value' : emptyString}`}>
					{hasIncompleteTimeRange ? NO_VALUE : totalHoursFormatted}
				</span>
			</div>
			<div className='work-log-column actions-column'>
				<div
					className='dots-icon-wrapper'
					onClick={(e) => {
						e.stopPropagation()
						onDotsClick()
					}}>
					<DotsThreeVertical />
				</div>
			</div>
		</div>
	)
}

export default WorkLogTableItem
