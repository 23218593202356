import { FunnelIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import colors from 'constants/colors'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import DailyFilterModal, { Filters } from './DailyFilterModal'
import './FilterComponent.css'

interface Props {
	filters: Filters
	setFilters: Dispatch<SetStateAction<Filters>>
	onApplyFilters: (filters: Filters) => void
	onResetFilters: () => void
	isFiltering: boolean
}

const FilterComponent: FC<Props> = (props) => {
	const { filters, setFilters, onApplyFilters, onResetFilters, isFiltering } = props
	const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)
	const { t } = useTranslation()

	const handleFilterModal = () => setIsOpenFilterModal((prevState) => !prevState)

	const handleApplyButtonClick = () => {
		onApplyFilters(filters)
		setIsOpenFilterModal(false)
	}

	return (
		<div className='filter-component'>
			<ButtonComponent
				className={`daily-filter-button ${
					isFiltering ? 'daily-filter-button-active' : emptyString
				}`}
				onClick={handleFilterModal}
				leftIcon={<FunnelIcon fillColor={isFiltering ? colors.violetMt : colors.blueMt} />}>
				{t('daily.filter')}
			</ButtonComponent>
			<DailyFilterModal
				isOpen={isOpenFilterModal}
				setIsOpen={setIsOpenFilterModal}
				filters={filters}
				setFilters={setFilters}
				handleResetFilters={onResetFilters}
				handleApplyFilters={handleApplyButtonClick}
			/>
		</div>
	)
}

export default FilterComponent
