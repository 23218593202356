import DayPickerComponent from 'components/elements/DayPickerComponent/DayPickerComponent'
import InputButton from 'components/elements/InputButton/InputButton'
import { RefObject, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import { dateToSpanish, weekNameLong } from 'utils/dateUtils'
import './Input.css'

interface Props {
	value: Date | undefined
	id: string
	onSelect: (day?: Date) => void
	showWeekDay?: boolean
	label?: string
	error?: string
}

const InputDate = forwardRef<HTMLElement, Props>((props, ref) => {
	const { value, onSelect, id, label, error, showWeekDay = true } = props
	const { t } = useTranslation()

	const [selected, setSelected] = useState<Date | undefined>(value)
	const [isPickerOpen, setIsPickerOpen] = useState(false)
	const [selectedDate, setSelectedDate] = useState<string>()

	useEffect(() => {
		const datePlaceholder = isToday(selected)
			? `${dateToSpanish(selected)} (${t('general.today')})`
			: dateToSpanish(selected) +
			  (selected && showWeekDay ? ` (${weekNameLong(selected)})` : emptyString)
		setSelectedDate(selected && datePlaceholder)
	}, [selected])

	useEffect(() => {
		setSelected(value)
	}, [value])

	const handleInputClick = () => {
		setIsPickerOpen(true)
	}

	const handleDateSelect = (day?: Date) => {
		setSelected(day)
		onSelect(day)
		setIsPickerOpen(false)
	}

	const isToday = (day: Date | undefined) => {
		if (!day) return false
		const today = new Date()
		return (
			day.getDate() === today.getDate() &&
			day.getMonth() === today.getMonth() &&
			day.getFullYear() === today.getFullYear()
		)
	}

	return (
		<div>
			<div className={`input-date ${error ? 'error' : emptyString}`}>
				<label htmlFor={id}>
					{label}
					{/* TODO: no tengo claro como gestionar los campos porque había pensado en crear un isRequired o un isOptional
						Pero creo que la decision se debe tomar dependiendo de si queremos mostrar * para los que si, o optional para los que no
					*/}
					{/* <span className='optional'> ({t('general.optional')})</span> */}
				</label>
				<InputButton
					id={id}
					type='date'
					onClick={handleInputClick}
					text={selectedDate}
					placeholder={t('daily.select_date')}
					error={error}
					ref={ref as RefObject<HTMLButtonElement>}
				/>
				{error && <span className='input-error-message'>{error}</span>}
			</div>
			{isPickerOpen && (
				<DayPickerComponent
					selected={selected}
					onSelect={handleDateSelect}
					onClose={() => setIsPickerOpen(false)}
				/>
			)}
		</div>
	)
})

export default InputDate
