import Input from 'components/elements/Input/Input'
import NumberAdjuster from 'components/elements/NumberAdjuster/NumberAdjuster'
import SelectDriverOptions from 'components/elements/SelectOptions/SelectDriverOptions'
import SelectProviderOptions from 'components/elements/SelectOptions/SelectProviderOptions'
import SelectVehicleOptions from 'components/elements/SelectOptions/SelectVehicleOptions'
import { FormikProps } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import { Permissions } from 'models/Configurations'
import { AllStepForms, AllStepFormsWithFomentoForm, StepDetailsForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik:
		| FormikProps<StepDetailsForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>

	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

const StepDetailsInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props

	const { t } = useTranslation()

	const { isConfigurationActive } = useConfigurations()

	return (
		<>
			{isConfigurationActive(Permissions.SHOW_EXTERNAL_ID) && (
				<Input
					type='text'
					id='externalId'
					label={t('transfer.booking_number')}
					placeholder={t('transfer.booking_number')}
					onChange={(e) => formik.setFieldValue('externalId', e.target.value)}
					valueSelected={formik.values['externalId']?.toString()}
					error={getFormikError(formik as FormikProps<StepDetailsForm>, 'externalId')}
					ref={setFieldRef ? setFieldRef('externalId') : undefined}
				/>
			)}
			<SelectProviderOptions
				onSelect={(providerId) => formik.setFieldValue('providerId', providerId)}
				selected={formik.values['providerId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'providerId')}
				label={t('transfer.provider')}
				setFieldRef={setFieldRef}
			/>
			<SelectDriverOptions
				onSelect={(driverId) => formik.setFieldValue('driverId', driverId)}
				selectedId={formik.values['driverId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'driverId')}
				label={t('transfer.driver')}
				setFieldRef={setFieldRef}
			/>
			<SelectVehicleOptions
				onSelect={(vehicleId) => formik.setFieldValue('vehicleId', vehicleId)}
				selectedId={formik.values['vehicleId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'vehicleId')}
				label={t('transfer.vehicle')}
				isLabelOptional
			/>
			<NumberAdjuster
				dataTest='pax'
				style={{ width: '4ch' }}
				title={t('transfer.numberOfPassengers')}
				onChange={(passengers) => formik.setFieldValue('passengers', passengers)}
				max={60}
				valueSelected={formik.values['passengers']}
			/>
		</>
	)
}

export default StepDetailsInputs
