import { useTranslation } from 'react-i18next'

const WorkLogTableHeader = () => {
	const { t } = useTranslation()
	return (
		<div className='work-log-table-header'>
			<div className='work-log-column day-column'></div>
			<div className='work-log-column start-time-column'>{t('work-log.start')}</div>
			<div className='work-log-column separator-column'></div>
			<div className='work-log-column end-time-column'>{t('work-log.end')}</div>
			<div className='work-log-column total-column'>{t('work-log.total')}</div>
			<div className='work-log-column actions-column'></div>
		</div>
	)
}

export default WorkLogTableHeader
