import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	fomentoQueryKey,
	sharedTransfersQueryKey,
	startEndTransfersQueryKey,
	transferQueryKey
} from 'constants/reactQueryKeys'
import useUser from 'hooks/useUser'
import { Fomento, SharedTransfer, StartEndTransfer, TypeStartEndTransfer } from 'models/index'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFomentoByBookingIdService } from 'services/fomento'
import {
	getTransferDetailsByIdService,
	getTransferSharedByBookingId,
	getTransferToClipboard
} from 'services/transfers'
import {
	endTransferService,
	getStartEndTransfersByTransferIdService,
	startTransferService
} from 'services/transfersStartEnd'
import { getUserId } from 'services/utils'
import { currentUserIsAdmin } from 'utils/currentUser'
import useDriver from './useDriver'
import useProvider from './useProvider'
import useToast from './useToast'
import useVehicle from './useVehicle'

export const useTransferDetails = (id: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const [fomento, setFomento] = useState<Fomento>()
	const [accepted, setAccepted] = useState<SharedTransfer>()
	const [pendings, setPendings] = useState<SharedTransfer[]>([])
	const [hasStart, setHasStart] = useState<boolean>(false)
	const [hasEnd, setHasEnd] = useState<boolean>(false)
	const [startEndDetails, setStartEndDetails] = useState<StartEndTransfer | null>(null)
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const currentUserId = getUserId()
	const isAdmin = currentUserIsAdmin()
	const { me } = useUser(currentUserId)

	const { showBackendErrorToast, showSuccessToast } = useToast()

	// Fetch transfer details by ID
	const {
		data: transfer,
		refetch: fetchDetails,
		isLoading: isLoadingTransfer,
		error: transferError,
		isError: isErrorTransfer
	} = useQuery({
		queryKey: [transferQueryKey, id],
		queryFn: () => getTransferDetailsByIdService(id),
		enabled: !isNaN(id)
	})

	const { provider } = useProvider(transfer?.providerId)
	const { driver } = useDriver(transfer?.driverId)
	const { vehicle } = useVehicle(transfer?.vehicleId)
	const { user } = useUser(transfer?.employeeId)

	// Fetch Fomento by Booking ID
	const {
		data: fetchedFomento,
		refetch: fetchFomentoByBookingId,
		isLoading: isLoadingFomento
	} = useQuery({
		queryKey: [fomentoQueryKey, id],
		queryFn: () => getFomentoByBookingIdService(id),
		enabled: !isNaN(id)
	})

	// Fetch shared transfers by Booking ID
	const { data: sharedTransfers, refetch: fetchSharedTransfersByBookingId } = useQuery({
		queryKey: [sharedTransfersQueryKey, id],
		queryFn: () => getTransferSharedByBookingId(id),
		enabled: !isNaN(id)
	})

	// Fetch StartEndTransfers by Booking ID
	const {
		data: startEndTransfers,
		refetch: fetchStartEndTransfersByBookingId,
		isLoading: isLoadingStartEndTransfers
	} = useQuery({
		queryKey: [startEndTransfersQueryKey, id],
		queryFn: () => getStartEndTransfersByTransferIdService(id),
		enabled: !isNaN(id) && !!transfer?.id,
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	// Fetch all necessary data when hasFetchData is true
	const fetchAll = () => {
		fetchFomentoByBookingId()
		fetchSharedTransfersByBookingId()
		fetchDetails()
		fetchStartEndTransfersByBookingId()
	}

	useEffect(() => {
		if (sharedTransfers) {
			getSharedInfo(sharedTransfers)
		}
	}, [sharedTransfers])

	useEffect(() => {
		if (startEndTransfers && transfer?.id) {
			getStartEndInfo(startEndTransfers)
		}
	}, [startEndTransfers, transfer?.id])

	useEffect(() => {
		if (fetchedFomento) {
			setFomento(fetchedFomento)
		}
	}, [fetchedFomento])

	useEffect(() => {
		if (isErrorTransfer && transferError) {
			setErrorMessage(t('transfer.error_load_transfer'))
		}
	}, [isErrorTransfer, transferError, t])

	const getSharedInfo = (sharedTransfers: SharedTransfer[]) => {
		const acceptedTransfer = sharedTransfers.find((transfer) => transfer.acceptedTime !== null)
		if (acceptedTransfer) {
			setAccepted(acceptedTransfer)
		}

		const pendingTransfers = sharedTransfers.filter(
			(transfer) => transfer.acceptedTime === null && transfer.rejectedTime === null
		)
		setPendings(pendingTransfers)
	}

	const getStartEndInfo = (startEndTransfers: StartEndTransfer[]) => {
		if (!startEndTransfers?.length || !transfer?.id) return

		const startEndByDriverId = startEndTransfers.filter((startEnd) => {
			const driverMatch = startEnd.driverId === transfer.driverId
			const bookingMatch = startEnd.bookingId === transfer.id

			if (isAdmin) {
				return driverMatch && bookingMatch
			}
			return driverMatch && bookingMatch && me?.allowedDrivers?.includes(startEnd.driverId)
		})

		if (startEndByDriverId.length) {
			const startTransfer = startEndByDriverId.find((t) => t.type === TypeStartEndTransfer.start)
			const endTransfer = startEndByDriverId.find((t) => t.type === TypeStartEndTransfer.end)
			setStartEndDetails(startTransfer || endTransfer || null)
			setHasStart(!!startTransfer)
			setHasEnd(!!endTransfer)
		} else {
			setStartEndDetails(null)
			setHasStart(false)
			setHasEnd(false)
		}
	}

	//START SERVICE
	const { mutateAsync: startTransfer } = useMutation({
		mutationFn: startTransferService,
		onSuccess: () => {
			showSuccessToast({
				description: t('transfer.success_start')
			})
			queryClient.invalidateQueries({
				queryKey: [startEndTransfersQueryKey, id]
			})
		},
		onError: (error) => {
			showBackendErrorToast(error)
			setErrorMessage(t('transfer.error_start'))
		}
	})

	//END SERVICE
	const { mutateAsync: endTransfer } = useMutation({
		mutationFn: endTransferService,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [startEndTransfersQueryKey, id]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_end'))
		}
	})

	const copyTransferToClipboard = async (id: number) => await getTransferToClipboard(id)

	return {
		transfer,
		provider,
		driver,
		vehicle,
		user,
		fomento,
		isLoadingFomento,
		accepted,
		pendings,
		hasStart,
		hasEnd,
		startEndDetails,
		onRefresh: fetchAll,
		error: errorMessage,
		loading: isLoadingTransfer,
		isLoadingStartEndTransfers,
		startTransfer,
		endTransfer,
		copyTransferToClipboard
	}
}
