import { Camera, CameraResultType, CameraSource, PermissionStatus } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'
import { ImageSquareIcon, UploadSimpleIcon } from 'assets/svgs'
import { useState } from 'react'
import { firstElement } from 'utils/common'
import Loading from '../Loading/Loading'
import './ProfileImageInput.css'

interface Props {
	onChange?: (file: File) => void
	id?: string
	label?: string
	accept?: string
	isLoading?: boolean
	showIcon?: boolean
	image?: string | null
	editable?: boolean
}

const ProfileImageInput: React.FC<Props> = (props) => {
	const {
		onChange,
		id,
		label,
		accept,
		isLoading = false,
		showIcon = true,
		image,
		editable = true
	} = props

	const [previewImage, setPreviewImage] = useState<string | null>(image || null)

	const ensureGalleryPermissions = async (): Promise<boolean> => {
		const permissions: PermissionStatus = await Camera.checkPermissions()

		if (permissions.photos === 'granted') {
			return true
		}

		const newPermissions: PermissionStatus = await Camera.requestPermissions()
		return newPermissions.photos === 'granted'
	}

	const urlToFile = async (url: string, fileName: string, mimeType: string): Promise<File> => {
		const response = await fetch(url)
		const blob = await response.blob()
		return new File([blob], fileName, { type: mimeType })
	}

	const getFileFromCapacitorPath = async (path: string, mimeType: string): Promise<File | null> => {
		try {
			const file = await Filesystem.readFile({ path })

			if (typeof file.data !== 'string') {
				console.error('El archivo leído no contiene una string válida.')
				return null
			}

			const byteCharacters = atob(file.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const blob = new Blob([byteArray], { type: mimeType })

			return new File([blob], 'image.jpg', { type: mimeType })
		} catch (error) {
			console.error('Error al convertir la imagen de iOS:', error)
			return null
		}
	}

	const pickImage = async () => {
		try {
			const platform = Capacitor.getPlatform()

			if (platform === 'ios' || platform === 'android') {
				const hasPermission = await ensureGalleryPermissions()
				if (!hasPermission) return

				const image = await Camera.getPhoto({
					source: CameraSource.Photos,
					resultType: CameraResultType.Uri,
					quality: 90
				})

				if (!image.webPath || !image.format) {
					console.error('No se pudo obtener la imagen.')
					return
				}

				let fileToUpload: File | null = null

				if (platform === 'ios' && image.path) {
					fileToUpload = await getFileFromCapacitorPath(image.path, `image/${image.format}`)
				} else {
					fileToUpload = await urlToFile(image.webPath, 'image.jpg', `image/${image.format}`)
				}

				if (fileToUpload) {
					setPreviewImage(URL.createObjectURL(fileToUpload))
					if (onChange) onChange(fileToUpload)
				}
			} else {
				const input = document.createElement('input')
				input.type = 'file'
				input.accept = accept || 'image/*'
				input.onchange = (event: Event) => {
					const fileInput = event.target as HTMLInputElement
					const newFile = fileInput.files ? fileInput.files[firstElement] : null

					if (newFile) {
						setPreviewImage(URL.createObjectURL(newFile))
						if (onChange) onChange(newFile)
					}
				}
				input.click()
			}
		} catch (error) {
			console.error('Error al seleccionar la imagen:', error)
		}
	}

	return (
		<div>
			{label && <label className='label'>{label}</label>}
			<div className='profile-image-input-wrapper'>
				<label
					className={`profile-image-input-image-placeholder ${isLoading ? 'loading' : ''} ${
						editable ? 'editable' : ''
					}`}
					htmlFor={id || 'profile-image-input'}
					onClick={editable ? pickImage : undefined}>
					{isLoading ? (
						<Loading hasText={false} />
					) : (
						<>
							<div className='profile-image-input-image-placeholder-bg'>
								{previewImage ? (
									<img className='profile-image-input-image' src={previewImage} alt='Selected' />
								) : (
									<ImageSquareIcon />
								)}
							</div>
							{showIcon && (
								<div className='profile-image-input-upload-icon'>
									<UploadSimpleIcon />
								</div>
							)}
						</>
					)}
				</label>
			</div>
		</div>
	)
}

export default ProfileImageInput
