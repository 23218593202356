import DotsThreeVertical from 'assets/svgs/DotsThreeVertical'
import BillingModal from 'components/Settings/Billing/BillingModal'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ReceiptCard.css'

interface Props {
	concept?: string
	reference: string
	total: string
	status: string
	date: string
	client?: string
	hasThreeDotsMenu?: boolean
	onClick?: () => void
}

const ReceiptCard: FC<Props> = (props) => {
	const {
		concept,
		reference,
		total,
		status,
		date,
		client,
		hasThreeDotsMenu = false,
		onClick
	} = props

	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const handleThreeDotsClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<button className='receipt-card' onClick={onClick}>
			<div className='receipt-card-row'>
				<div className='receipt-card-item'>
					<span className='body2'>{t('general.date')}</span>
					<span className='receipt-card-value body1'>{date}</span>
				</div>
				<div className='receipt-card-item'>
					<span className='body2'>{t('settings.my_account.subscription.reference')}</span>
					<span className='receipt-card-value body1'>{reference}</span>
				</div>
				{hasThreeDotsMenu && (
					<div className='receipt-card-item'>
						<div className='receipt-card-item-three-dots-container' onClick={handleThreeDotsClick}>
							<DotsThreeVertical />
						</div>
						<BillingModal isOpen={isOpen} setIsOpen={setIsOpen} invoiceId={reference} />
					</div>
				)}
			</div>
			<div className='receipt-card-row'>
				{client ? (
					<div className='receipt-card-item'>
						<span className='body2'>{t('settings.my_account.subscription.client')}</span>
						<span className='receipt-card-value body1'>{client}</span>
					</div>
				) : (
					<div className='receipt-card-item'>
						<span className='body2'>{t('settings.my_account.subscription.period')}</span>
						<span className='receipt-card-value body1'>{concept}</span>
					</div>
				)}

				<div className='receipt-card-item'>
					<span className='body2'>{t('settings.my_account.subscription.total')}</span>
					<span className='receipt-card-value body1'>{total}</span>
				</div>
				<div className='receipt-card-item'>
					<span className='body2'>{t('settings.my_account.subscription.status')}</span>
					<span className='receipt-card-value body1'>{status}</span>
				</div>
			</div>
		</button>
	)
}

export default ReceiptCard
