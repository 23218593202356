import { AppLauncher } from '@capacitor/app-launcher'
import { Capacitor } from '@capacitor/core'

import { ArrowSquareOutIcon } from 'assets/svgs'
import ThreeDotsModal from 'components/elements/ThreeDotsModal/ThreeDotsModal'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	address: string
}

const TransferDetailsMapModal: FC<Props> = ({ isOpen, setIsOpen, address }) => {
	const { t } = useTranslation()
	const encodedAddress = encodeURIComponent(address)

	const handleOpenGoogleMaps = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
		if (Capacitor.isNativePlatform()) {
			const isAvailable = await AppLauncher.canOpenUrl({ url })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url })
			} else {
				window.open(url, '_blank')
			}
		} else {
			window.open(url, '_blank')
		}
		setIsOpen(false)
	}

	const handleOpenWaze = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const url = `https://waze.com/ul?q=${encodedAddress}`
		if (Capacitor.isNativePlatform()) {
			const isAvailable = await AppLauncher.canOpenUrl({ url })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url })
			} else {
				window.open(url, '_blank')
			}
		} else {
			window.open(url, '_blank')
		}
		setIsOpen(false)
	}

	const menuItems = useMemo(() => {
		return [
			{
				label: t('transfer_details.map_modal.open_google_maps'),
				href: '#',
				icon: <ArrowSquareOutIcon />,
				onClick: handleOpenGoogleMaps
			},
			{
				label: t('transfer_details.map_modal.open_waze'),
				href: '#',
				icon: <ArrowSquareOutIcon />,
				onClick: handleOpenWaze
			}
		]
	}, [address])

	return <ThreeDotsModal isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} />
}

export default TransferDetailsMapModal
