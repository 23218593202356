import { useMutation, useQuery } from '@tanstack/react-query'
import { UserRoles } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	checkIfTransferIsReviewedService,
	getTransferReviewsByBookingIdService,
	saveTransferReviewService
} from 'services/transferReview'
import { toast } from 'sonner'
import { useUserStore } from 'stores/useUserStore'

export const useTransferReview = (bookingId?: number) => {
	const { t } = useTranslation()

	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	const userState = useUserStore()
	const currentUser = userState.currentUser
	const isDriver = currentUser.userTypeId === UserRoles.driver
	const isAdmin =
		currentUser.userTypeId === UserRoles.admin ||
		currentUser.userTypeId === UserRoles['super-admin']

	//DRIVER checkIfTransferIsReviewedService
	const {
		data: transferReview,
		isLoading: isLoadingTransferReview,
		error: transferReviewError,
		isError: isErrorTransferReview
	} = useQuery({
		queryKey: ['transferReview', bookingId],
		queryFn: () => checkIfTransferIsReviewedService(bookingId!),
		enabled: !!bookingId && isDriver,
		refetchOnWindowFocus: true
	})

	//ADMIN getTransferReviewsByBookingIdService
	const {
		data: adminTransferReviews,
		isLoading: isLoadingAdminTransferReviews,
		error: adminTransferReviewsError,
		isError: isErrorAdminTransferReviews
	} = useQuery({
		queryKey: ['adminTransferReviews', bookingId],
		queryFn: () => getTransferReviewsByBookingIdService(bookingId!),
		enabled: !!bookingId && isAdmin,
		refetchOnWindowFocus: true
	})

	//UPDATE TRANSFER
	const { mutateAsync: saveTransferReview } = useMutation({
		mutationFn: saveTransferReviewService,
		onSuccess: () => {
			toast.success(t('transfer_review.success'))
		},
		onError: () => {
			setErrorMessage(t('transfer_review.error'))
		}
	})

	// if user is driver and transfer is not reviewed, mark as reviewed
	useEffect(() => {
		if (!isDriver || !bookingId || isLoadingTransferReview) return
		if (transferReview) {
			return
		}

		saveTransferReview(bookingId).catch(() => {
			console.error('❌ Error al marcar como revisado')
		})
	}, [transferReview, isDriver, bookingId, isLoadingTransferReview])

	return {
		transferReview,
		isLoadingTransferReview,
		isErrorTransferReview,
		transferReviewError,
		saveTransferReview,
		errorMessage,
		adminTransferReviews: adminTransferReviews ? adminTransferReviews : [],
		isLoadingAdminTransferReviews,
		isErrorAdminTransferReviews,
		adminTransferReviewsError
	}
}
