import { ArrowSquareOutIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import TransferDetailsMapModal from 'components/Transfer/TransferDetailsMapModal'

import { FC, useState } from 'react'

interface Props {
	address: string
}

const MapLink: FC<Props> = ({ address }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<ButtonComponent role='link' variant='only-icon' onClick={() => setIsOpen(true)}>
				<ArrowSquareOutIcon />
			</ButtonComponent>
			<TransferDetailsMapModal isOpen={isOpen} setIsOpen={setIsOpen} address={address} />
		</>
	)
}

export default MapLink
