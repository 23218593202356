import ExportModal from 'components/elements/ExportModal/ExportModal'
import { useModal } from 'hooks/useModal'
import useToast from 'hooks/useToast'
import { ExportFormat, WorkLogExportType } from 'models/Elements'
import { WorkLogExport } from 'models/WorkLog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { exportWorkLogPdfService, exportWorkLogXlsService } from 'services/workLog'
import downloadAndOpenFile from 'utils/downloadAndOpenFile'

interface Props {
	isVisible: boolean
	toggleModalVisibility: () => void
	filter: WorkLogExport
}

const ExportWorkLogModal = ({ isVisible, toggleModalVisibility, filter }: Props) => {
	const { showSuccessToast, showBackendErrorToast } = useToast()
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()
	const [generateDocModalIsVisible, toggleGenerateDocModalVisibility] = useModal()

	const handleExport = async (type: WorkLogExportType) => {
		setLoading(true)
		try {
			if (type === ExportFormat.xls) {
				const xls = await exportWorkLogXlsService(filter)
				await downloadXls(xls)
			}

			if (type === ExportFormat.pdf) {
				const pdf = await exportWorkLogPdfService(filter)
				await downloadPdf(pdf)
			}
			showSuccessToast({
				description: t('work-log.export_modal.success'),
				duration: 2000
			})
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			toggleGenerateDocModalVisibility()
			toggleModalVisibility()
		}
	}

	const downloadPdf = async (pdfString: string) => {
		await downloadAndOpenFile({
			fileBase64: pdfString,
			fileName: 'listado_jornadas.pdf',
			mimeType: 'application/pdf',
			showErrorToast: showBackendErrorToast
		})
	}

	const downloadXls = async (xmlString: string) => {
		await downloadAndOpenFile({
			fileBase64: xmlString,
			fileName: 'listado_jornadas.xls',
			mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			showErrorToast: showBackendErrorToast
		})
	}

	return (
		<ExportModal
			isVisible={isVisible}
			toggleModalVisibility={toggleModalVisibility}
			select={handleExport}
			generateDocModalIsVisible={generateDocModalIsVisible}
			toggleGenerateDocModalVisibility={toggleGenerateDocModalVisibility}
			loading={loading}
			title={t('work-log.export_modal.title')}
			xlsButtonText={t('work-log.export_modal.xls')}
			pdfButtonText={t('work-log.export_modal.pdf')}
			generateButtonText={t('work-log.export_modal.generate_document')}
			generateDescriptionText={t('work-log.export_modal.description')}
		/>
	)
}

export default ExportWorkLogModal
