import { CaretCircleDownIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import InputDate from 'components/elements/Input/InputDate'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import { useModal } from 'hooks/useModal'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { firstElement } from 'utils/common'
import { dateToSQL, formatCustomRangeDate, getDateRange } from 'utils/dateUtils'
import InputButton from '../InputButton/InputButton'

const CUSTOM_DATE_RANGE_OPTION_ID = 5

interface dateRange {
	startDate: string
	endDate: string
}

interface Props {
	onSelect: ({ startDate, endDate }: dateRange) => void
	label?: string
	isLabelOptional?: boolean
	id?: string
	selectAppearance?: boolean
}

const SelectDateRangeOptions: React.FC<Props> = (props) => {
	const {
		onSelect,
		label,
		isLabelOptional = false,
		id = 'dateRange',
		selectAppearance = false
	} = props

	const [dropdownDateModalIsVisible, toggleDropdownDateModalVisibility] = useModal()
	const [customDateModalIsVisible, toggleCustomDateModalVisibility] = useModal()
	const [selectedDateRange, setSelectedDateRange] = useState<number>(firstElement)
	const [customStartDate, setCustomStartDate] = useState<Date>()
	const [customEndDate, setCustomEndDate] = useState<Date>()

	const { t } = useTranslation()

	const DATE_RANGE_OPTIONS = [
		{ id: 0, name: t('historical.date_range_options.last_15_days') },
		{ id: 1, name: t('historical.date_range_options.last_30_days') },
		{ id: 2, name: t('historical.date_range_options.last_90_days') },
		{ id: 3, name: t('historical.date_range_options.last_365_days') },
		{ id: 4, name: t('historical.date_range_options.today') },
		{ id: 5, name: t('historical.date_range_options.custom_days') }
	]

	const onSelectDateRange = (ids: number[]) => {
		const selectedId = ids[firstElement]
		if (selectedId === CUSTOM_DATE_RANGE_OPTION_ID) {
			toggleCustomDateModalVisibility()
		} else {
			onSelect(getDateRange(selectedId))
			setSelectedDateRange(selectedId)
			toggleDropdownDateModalVisibility()
		}
	}

	const onSelectCustomDateRange = () => {
		if (customStartDate && customEndDate) {
			setSelectedDateRange(CUSTOM_DATE_RANGE_OPTION_ID)
			onSelect({
				startDate: dateToSQL(customStartDate),
				endDate: dateToSQL(customEndDate)
			})
			toggleCustomDateModalVisibility()
		}
	}

	const valueSelected =
		selectedDateRange === CUSTOM_DATE_RANGE_OPTION_ID && customStartDate && customEndDate
			? `${formatCustomRangeDate(customStartDate)} - ${formatCustomRangeDate(customEndDate)}`
			: DATE_RANGE_OPTIONS[selectedDateRange].name

	return (
		<div className='select-option'>
			{!selectAppearance ? (
				<ButtonComponent
					className='select-date-range-button'
					rightIcon={<CaretCircleDownIcon />}
					onClick={toggleDropdownDateModalVisibility}>
					{valueSelected}
				</ButtonComponent>
			) : (
				<>
					<label htmlFor={id}>
						{label}
						{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
					</label>
					<InputButton
						id={id}
						placeholder={DATE_RANGE_OPTIONS[selectedDateRange].name}
						type='select'
						onClick={toggleDropdownDateModalVisibility}
					/>
				</>
			)}

			<DropdownSelectorModal
				title={t('form.select_at', { field: t('historical.date') })}
				visible={dropdownDateModalIsVisible}
				items={DATE_RANGE_OPTIONS}
				toggleVisibility={toggleDropdownDateModalVisibility}
				handleClick={onSelectDateRange}
			/>
			<ModalFormLayout
				isVisible={customDateModalIsVisible}
				toggleVisibility={toggleCustomDateModalVisibility}
				title={t('form.select_at', { field: t('historical.date') })}
				footer={
					<>
						<ButtonComponent
							fullWidth
							variant='secondary'
							onClick={toggleCustomDateModalVisibility}>
							{t('general.cancel')}
						</ButtonComponent>
						<ButtonComponent
							fullWidth
							variant='primary'
							disabled={!customStartDate || !customEndDate}
							onClick={onSelectCustomDateRange}>
							{t('general.update')}
						</ButtonComponent>
					</>
				}>
				<FormSectionLayout smallGap>
					<InputDate
						id='custom-start-date'
						value={customStartDate}
						onSelect={setCustomStartDate}
						label={t('historical.start_date')}
					/>
					<InputDate
						id='custom-end-date'
						value={customEndDate}
						onSelect={setCustomEndDate}
						label={t('historical.end_date')}
					/>
				</FormSectionLayout>
			</ModalFormLayout>
		</div>
	)
}

export default SelectDateRangeOptions
