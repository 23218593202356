import { DatesSetArg, EventClickArg } from '@fullcalendar/core/index.js'
import enLocale from '@fullcalendar/core/locales/en-gb'
import esLocale from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import colors from 'constants/colors'
import { DAILY_URL } from 'constants/routes'
import useCharts from 'hooks/useCharts'
import { CalendarResponse } from 'models/charts'
import { RouteTypeName } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { useLanguageStore } from 'stores/useLanguageStore'
import { dateToSQL } from 'utils/dateUtils'
import './Calendar.css'

const Calendar = () => {
	const { language } = useLanguageStore()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { colors: transferColors } = useConfigStore()

	const { setCalendarPayload, calendarStats } = useCharts()

	const groupDataByDateAndRoute = (calendarStats: CalendarResponse) => {
		const grouped: { [date: string]: { [key in RouteTypeName]: number } } = {}

		Object.entries(calendarStats).forEach(([date, data]) => {
			if (!grouped[date]) {
				grouped[date] = {
					[RouteTypeName.departure]: 0,
					[RouteTypeName.arrival]: 0,
					[RouteTypeName.transfer]: 0,
					[RouteTypeName.disposition]: 0,
					[RouteTypeName.crane]: 0
				}
			}
			Object.entries(data.totals).forEach(([routeType, count]) => {
				const validRouteType = routeType as RouteTypeName
				grouped[date][validRouteType] = (grouped[date][validRouteType] ?? 0) + (count ?? 0)
			})
		})
		return grouped
	}

	const formatEventsForCalendar = (groupedData: {
		[date: string]: { [key in RouteTypeName]: number }
	}) => {
		const events = []
		for (const [date, routeTypes] of Object.entries(groupedData)) {
			for (const [routeType, count] of Object.entries(routeTypes)) {
				if (count > 0) {
					const validRouteType = routeType as RouteTypeName
					events.push({
						title: `(${count}) ${t(`transfer.route.${validRouteType}s`)}`,
						date: date,
						backgroundColor: transferColors[validRouteType],
						borderColor: transferColors[validRouteType],
						textColor: colors.blueMt
					})
				}
			}
		}
		return events
	}

	const groupedData = groupDataByDateAndRoute(calendarStats)
	const events = formatEventsForCalendar(groupedData)

	const handleClick = (arg: EventClickArg) => {
		const selectedDate = arg.event.start
		const formattedDate = dateToSQL(selectedDate!)
		navigate(`${DAILY_URL}/${formattedDate}`)
	}

	const handleDateSet = (arg: DatesSetArg) => {
		const startDate = new Date(arg.start)
		const year = startDate.getFullYear().toString()
		const month = (startDate.getMonth() + 1).toString()
		setCalendarPayload((prevPayload) => {
			if (prevPayload.year === year && prevPayload.month === month) {
				return prevPayload
			}
			return { year, month }
		})
	}

	const calendarLocale = language === 'ES' ? esLocale : enLocale

	return (
		<FullCalendar
			plugins={[dayGridPlugin]}
			initialView='dayGridMonth'
			events={events}
			locale={calendarLocale}
			eventClick={handleClick}
			datesSet={(arg) => handleDateSet(arg)}
			showNonCurrentDates={false}
			height='auto'
		/>
	)
}

export default Calendar
