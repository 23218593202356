import ButtonComponent from 'components/elements/Button/Button'
import DropdownSelector, {
	ItemDropdown
} from 'components/elements/DropdownSelector/DropdownSelector'
import Header from 'components/elements/Header/Header'
import { Modal } from 'components/elements/Modal/Modal'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './DropdownSelectorModal.css'

interface Props {
	visible: boolean
	toggleVisibility: () => void
	items: ItemDropdown[]
	title: string
	description?: string
	subtitle?: string
	isMultiple?: boolean
	handleClick: (ids: number[]) => void
	handleReset?: () => void
	selected?: number | number[] | null
}

const DropdownSelectorModal: FC<Props> = (props) => {
	const {
		visible,
		toggleVisibility,
		items,
		title,
		description,
		subtitle,
		isMultiple,
		handleClick,
		handleReset,
		selected
	} = props

	const orderBy = 'name' // change this to 'id' to order by id instead of name

	const { t } = useTranslation()

	const [selectedItems, setSelectedItems] = useState<number[]>([])

	const searchInputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (visible && searchInputRef.current) {
			searchInputRef.current.focus()
		}
	}, [visible])

	const onSaveMultiple = () => {
		handleClick(selectedItems)
		closeDropdownSelectorModal()
	}

	const closeDropdownSelectorModal = () => {
		toggleVisibility()
	}

	const orderItemsBy = (items: ItemDropdown[], by: keyof ItemDropdown) => {
		return items.sort((a, b) => {
			const aValue = a[by]?.toString() ?? emptyString
			const bValue = b[by]?.toString() ?? emptyString
			return naturalSort(aValue, bValue)
		})
	}

	const naturalSort = (a: string, b: string) => {
		const regex = /(\d+|\D+)/g

		const aParts = a.match(regex) || []
		const bParts = b.match(regex) || []

		for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
			const aNum = parseInt(aParts[i])
			const bNum = parseInt(bParts[i])

			if (!isNaN(aNum) && !isNaN(bNum)) {
				if (aNum !== bNum) return aNum - bNum
			}
			const comp = aParts[i].localeCompare(bParts[i])
			if (comp !== 0) return comp
		}
		return aParts.length - bParts.length
	}

	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = emptyString
		}
		return () => {
			document.body.style.overflow = emptyString
		}
	}, [visible])

	return (
		<Modal
			isVisible={visible}
			showCloseButton={false}
			toggleVisibility={toggleVisibility}
			isFull
			customClassNames='dropdown-selector-modal'>
			<Header
				leftButton={
					<ButtonComponent variant='only-text' onClick={closeDropdownSelectorModal}>
						<strong>{t('general.return')}</strong>
					</ButtonComponent>
				}
				title={title}
				rightButton={
					isMultiple ? (
						<ButtonComponent variant='only-text' onClick={onSaveMultiple}>
							<strong>{t('general.save')}</strong>
						</ButtonComponent>
					) : (
						handleReset && (
							<ButtonComponent variant='only-text' onClick={handleReset}>
								<strong>{t('general.reset')}</strong>
							</ButtonComponent>
						)
					)
				}
				isSticky
			/>
			<DropdownSelector
				items={orderItemsBy(items, orderBy)}
				description={description}
				subtitle={subtitle}
				isMultiple={isMultiple}
				handleClick={(ids) => {
					handleClick(ids)
					closeDropdownSelectorModal()
				}}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
				searchInputRef={searchInputRef}
				selected={selected}
			/>
		</Modal>
	)
}

export default DropdownSelectorModal
