import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { ThemeProvider } from '@mui/material/styles'
import { WarningIcon } from 'assets/svgs'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { LOGOUT_URL } from 'constants/routes'
import useInitializeAppData from 'hooks/useInitializeAppData'
import { useModal } from 'hooks/useModal'
import useSupport from 'hooks/useSupport'
// import useToast from 'hooks/useToast'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { Toaster } from 'sonner'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { useUserStore } from 'stores/useUserStore'
// import { emptyString } from 'utils/common'
import { saveFirebaseToken } from 'services/notifications'
import { materialTheme } from './designSystem/materialTheme'
import { UserRoles } from './models'
import { RoutesContainer } from './routes/routes'

function AppContent() {
	const { getAllData, isLoading } = useInitializeAppData()
	const userState = useUserStore()
	const currentUser = userState.currentUser
	const [isBlockedAccessModalVisible, toggleBlockedAccessModalVisibility] = useModal()
	const handleSupport = useSupport()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const { resetPreviousRoute, isResettable, setResettable } = usePreviousRouteStore()
	// const { showSuccessToast } = useToast()

	const setupPushNotificationChannel = async () => {
		try {
			if (Capacitor.getPlatform() === 'android') {
				await PushNotifications.createChannel({
					id: 'default',
					name: 'Default Notifications',
					description: 'Canal para notificaciones generales',
					importance: 5,
					visibility: 1
				})
			}
		} catch (error) {
			console.error('Error al crear el canal de notificaciones:', error)
		}
	}

	const setupFirebaseNotifications = async () => {
		if (Capacitor.getPlatform() === 'web') return

		try {
			const permission = await PushNotifications.requestPermissions()
			if (permission.receive === 'granted') {
				await FirebaseMessaging.removeAllListeners()
				await PushNotifications.removeAllListeners()
				await LocalNotifications.removeAllListeners()

				await PushNotifications.register()
				await setupPushNotificationChannel()

				await refreshFirebaseToken()

				if (Capacitor.getPlatform() === 'android') {
					await LocalNotifications.requestPermissions()
				}

				if (Capacitor.getPlatform() === 'ios') {
					PushNotifications.addListener('pushNotificationReceived', async (notification) => {
						const data = notification.data || {}

						if (notification.title && notification.body) {
							await LocalNotifications.schedule({
								notifications: [
									{
										id: parseInt(notification.id),
										title: notification.title,
										body: notification.body,
										sound: 'default',
										smallIcon: 'ic_stat_notifications',
										largeIcon: 'ic_stat_notifications',
										extra: data
									}
								]
							})
						}
					})

					LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
						const data = action.notification.extra || {}

						if (data?.url) {
							navigate(data.url)
						}
					})
				}

				if (Capacitor.getPlatform() === 'android') {
					FirebaseMessaging.addListener('notificationReceived', async (message) => {
						const data = message.notification?.data || {}

						if (message.notification?.title && message.notification?.body) {
							await LocalNotifications.schedule({
								notifications: [
									{
										id: Math.floor(Math.random() * 100000),
										title: message.notification?.title,
										body: message.notification?.body,
										sound: 'default',
										autoCancel: true,
										extra: data
									}
								]
							})
						}
					})

					LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
						const data = action.notification.extra || {}

						if (data?.url) {
							navigate(data.url)
						}
					})
				}

				PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
					const data = action.notification.data || {}

					if (data?.url) {
						navigate(data.url)
					}
				})

				const notification = await PushNotifications.getDeliveredNotifications()
				if (notification?.notifications?.length > 0) {
					const lastNotification = notification.notifications[0]

					const data = lastNotification.data || {}

					if (data?.url) {
						navigate(data.url)
					}
				}
			}
		} catch (error) {
			console.error('❌ Error al configurar notificaciones:', error)
		}
	}

	useEffect(() => {
		setupFirebaseNotifications()

		return () => {
			if (Capacitor.getPlatform() === 'web') return
			PushNotifications.removeAllListeners()
			FirebaseMessaging.removeAllListeners()
			LocalNotifications.removeAllListeners()
		}
	}, [])

	useEffect(() => {
		if (currentUser.id) {
			getAllData()

			if (currentUser.userTypeId === UserRoles.driver) {
				if (!currentUser.allowedDrivers.length) {
					toggleBlockedAccessModalVisibility()
				}
			} else if (currentUser.userTypeId === UserRoles.provider) {
				if (!currentUser.allowedProviders.length) {
					toggleBlockedAccessModalVisibility()
				}
			}
		}
	}, [currentUser])

	useEffect(() => {
		if (isResettable) {
			resetPreviousRoute()
		} else {
			setResettable(true)
		}
	}, [location.pathname])

	const refreshFirebaseToken = async () => {
		if (Capacitor.getPlatform() === 'web') return
		try {
			const permission = await PushNotifications.requestPermissions()
			if (permission.receive === 'granted') {
				await PushNotifications.register()

				const { token } = await FirebaseMessaging.getToken()
				await saveFirebaseToken(token, Capacitor.getPlatform())
			}
		} catch (error) {
			console.error('Error al refrescar el token de Firebase:', error)
		}
	}

	if (isLoading && currentUser.id) {
		return <Loading fullscreen />
	}

	const onCloseBlockedAccessModal = () => {
		toggleBlockedAccessModalVisibility()
		navigate(LOGOUT_URL)
	}

	const onContactSupport = () => {
		onCloseBlockedAccessModal()
		handleSupport()
	}

	return (
		<div className={`App ${Capacitor.getPlatform()}-platform`}>
			<Toaster richColors />
			<RoutesContainer />
			<ModalActions
				isVisible={isBlockedAccessModalVisible}
				onClose={onCloseBlockedAccessModal}
				type='error'
				icon={<WarningIcon />}
				title={t('blocked_access_modal.title')}
				description={t('blocked_access_modal.description', {
					entity:
						currentUser.userTypeId === UserRoles.driver
							? t('general.driver').toLocaleLowerCase()
							: t('general.provider').toLocaleLowerCase()
				})}
				primaryButton={{
					text: t('blocked_access_modal.contact_support'),
					onClick: onContactSupport
				}}
				secondaryButton={{
					text: t('general.quit'),
					onClick: onCloseBlockedAccessModal
				}}
			/>
		</div>
	)
}

function App() {
	return (
		<ThemeProvider theme={materialTheme}>
			<BrowserRouter>
				<AppContent />
			</BrowserRouter>
		</ThemeProvider>
	)
}

export default App
