import SelectDateRangeOptions from 'components/elements/SelectOptions/SelectDateRangeOptions'
import SelectDriverOptions from 'components/elements/SelectOptions/SelectDriverOptions'
import SelectProviderOptions from 'components/elements/SelectOptions/SelectProviderOptions'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { WithReservationInvoiceFilters } from 'models/Billing'
import { RouteTypeName } from 'models/Transfer'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'

interface Props {
	filter: WithReservationInvoiceFilters
	setFilter: Dispatch<SetStateAction<WithReservationInvoiceFilters>>
	noProviderError: boolean
}

const WithReservationsInvoiceModalStep1 = ({ filter, setFilter, noProviderError }: Props) => {
	const { t } = useTranslation()
	const { routeTypes, colors } = useConfigStore()

	const handleDriverSelect = (id: number | number[] | undefined) => {
		setFilter((prev) => ({ ...prev, driverId: id }))
	}

	const handleProviderSelect = (id: number | number[] | null) => {
		setFilter((prev) => ({ ...prev, providerId: id }))
	}

	const handleRoutes = (routeType: RouteTypeName) => {
		setFilter((prev) => ({
			...prev,
			selectedRoutes: prev.selectedRoutes?.includes(routeType)
				? prev.selectedRoutes.filter((route) => route !== routeType)
				: [...(prev.selectedRoutes ?? []), routeType]
		}))
	}

	const isSelectedRoute = (routeType: RouteTypeName) =>
		filter.selectedRoutes?.includes(routeType) ?? false

	return (
		<FormSectionLayout>
			<p className='with-reservations-description'>
				{t('settings.billing.create_with_reservations_modal_description')}
			</p>
			<SelectDateRangeOptions
				label={t('general.date')}
				selectAppearance={true}
				onSelect={(range) => {
					setFilter((prevFilter) => ({
						...prevFilter,
						...range
					}))
				}}
			/>
			<SelectDriverOptions
				label={t('historical.filter_modal.label.driver')}
				onSelect={handleDriverSelect}
				selectedId={filter.driverId}
				isMultiple
			/>

			<SelectProviderOptions
				label={t('historical.filter_modal.label.provider')}
				onSelect={handleProviderSelect}
				selected={filter.providerId ?? null}
				error={noProviderError ? t('errors.required') : undefined}
			/>
			<div className='filter-by-route'>
				<label>{t('daily.filter_by_route')}</label>
				{routeTypes.allIds.map((id) => {
					const type = routeTypes.byId[id]
					return (
						<div
							key={type.id}
							className={`filter-card ${isSelectedRoute(type.name) ? 'filter-card-selected' : ''}`}
							style={{
								border: `1px solid ${colors[type.name]}`
							}}
							onClick={() => handleRoutes(type.name)}>
							<div
								className='filter-card-square'
								style={{ backgroundColor: colors[type.name] }}></div>
							<p>{t(`transfer.route.${type.name}`)}</p>
						</div>
					)
				})}
			</div>
		</FormSectionLayout>
	)
}

export default WithReservationsInvoiceModalStep1
