import { ListIcon, RowsIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import Loading from 'components/elements/Loading/Loading'
import TabContainer from 'components/elements/TabContainer/TabContainer'
import { DAILY_URL } from 'constants/routes'
import useDailyFilters from 'hooks/useDailyFilters'
import useFriends from 'hooks/useFriends'
import useTransfers from 'hooks/useTransfers'
import { useTransfersReview } from 'hooks/useTransfersReview'
import useTransfersShare from 'hooks/useTransfersShare'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { dateIsToday, dateToSQL, isValidDate } from 'utils/dateUtils'
import './DailyComponent.css'
import DailySearchBar from './DailySearchBar'
import { DayNavigationBlock } from './DayNavigationBlock'
import FilterComponent from './FilterComponent'
import NoTransfers from './NoTransfers'
import ShareTransferAlertComponent from './ShareTransferAlertComponent'
import TransfersList from './TransfersList'
import TransfersWithOutRevisionAlertComponent from './TransfersWithOutRevisionAlertComponent'
import TransferTags from './TransferTags'

const DailyComponent = () => {
	const navigate = useNavigate()
	const { date } = useParams()
	const [currentDate, setCurrentDate] = useState(dateToSQL(date ? new Date(date) : new Date()))
	const [selectedTab, setSelectedTab] = useState(0)
	const [isExpanding, setIsExpanding] = useState(false)
	const { t } = useTranslation()
	const { requestReceived } = useFriends()
	const { allSharedRequestsReceived } = useTransfersShare({})

	const {
		transfers,
		transfersExcluded,
		allTransfers,
		loading,
		error,
		onSearch,
		setAllTransfersTab,
		filterNextTransfers,
		fomento,
		startEndTransfers,
		sharedTransfers
	} = useTransfers(new Date(currentDate))

	const {
		filters,
		isFiltering,
		filteredTransfers,
		handleApplyFilters,
		handleResetFilters,
		removeFilterFromTag,
		setFilters
	} = useDailyFilters(transfers)

	const { adminTransferReviews } = useTransfersReview(currentDate)

	useEffect(() => {
		if (date && isValidDate(date)) {
			setCurrentDate(date)
			handleResetFilters()
		} else {
			navigate(`${DAILY_URL}/${dateToSQL()}`)
		}

		return () => {
			setCurrentDate(dateToSQL())
			selectedTab === 1 && setSelectedTab(0)
		}
	}, [date, navigate])

	const handleTabChange = (tabId: number) => {
		setSelectedTab(tabId)
		tabs[tabId].onSelect()
	}

	const tabs = [
		{
			id: 0,
			label: t('daily.tabs.all'),
			onSelect: () => setAllTransfersTab()
		},
		{
			id: 1,
			label: t('daily.tabs.next'),
			onSelect: () => filterNextTransfers()
		}
	]

	const hasTransfers = transfers.length > 0 || transfersExcluded.length > 0

	const handleExpandCards = () => setIsExpanding((prevState) => !prevState)

	if (error) {
		return <p>{error}</p>
	}

	return (
		<DashboardLayout>
			<div className='daily-wrapper center-content'>
				<div className='daily-header-container'>
					<HeaderWithNotificationBell title={t('daily.h1')} />
					<ShareTransferAlertComponent pending={allSharedRequestsReceived ?? []} />
					<ShareTransferAlertComponent pending={requestReceived} isFriendRequest />
					<TransfersWithOutRevisionAlertComponent />
					<DayNavigationBlock date={new Date(currentDate)} />
				</div>

				{dateIsToday(new Date(currentDate)) && hasTransfers && !loading && (
					<div className='daily-tabs-container'>
						<TabContainer tabs={tabs} onTabChange={handleTabChange} selectedTab={selectedTab} />
					</div>
				)}

				{!!allTransfers.length && !loading && (
					<div className={'daily-search-container'}>
						<DailySearchBar onSearch={onSearch} />
						<FilterComponent
							filters={filters}
							setFilters={setFilters}
							onApplyFilters={handleApplyFilters}
							onResetFilters={handleResetFilters}
							isFiltering={isFiltering}
						/>
						<ButtonComponent
							className={`daily-detail-button ${
								isExpanding ? 'daily-detail-button-active' : emptyString
							}`}
							onClick={handleExpandCards}
							leftIcon={isExpanding ? <RowsIcon /> : <ListIcon />}>
							{isExpanding ? t('daily.compact') : t('daily.detailed')}
						</ButtonComponent>
					</div>
				)}

				{(filters.selectedRoutes.length || filters.selectedDriver || filters.selectedProvider) && (
					<TransferTags filters={filters} removeFilterFromTag={removeFilterFromTag} />
				)}

				{loading ? (
					<Loading />
				) : (
					<>
						{hasTransfers ? (
							filteredTransfers.length > 0 ? (
								<TransfersList
									isExpanding={isExpanding}
									transfers={filteredTransfers}
									fomento={fomento}
									startEndTransfers={startEndTransfers}
									sharedTransfers={sharedTransfers}
									transferReviews={adminTransferReviews}
								/>
							) : transfersExcluded.length > 0 && transfers.length === 0 ? (
								<NoTransfers messageKey={t('daily.no_more_today')} />
							) : (
								<NoTransfers messageKey={t('daily.no_filtered_transfers')} />
							)
						) : (
							<NoTransfers messageKey={t('daily.no_items')} />
						)}
					</>
				)}
			</div>
		</DashboardLayout>
	)
}

export default DailyComponent
