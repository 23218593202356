import { MapPinIcon, MapPinLineIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import { TRANSFER_URL } from 'constants/routes'
import useDriver from 'hooks/useDriver'
import useRouteColors from 'hooks/useRouteColors'
import { StatsLastTransfersResponse } from 'models/charts'
import { Transfer } from 'models/Transfer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { emptyString } from 'utils/common'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import './SmallTransferCard.css'

interface Props {
	transfer: StatsLastTransfersResponse | Transfer
	onClick?: () => void
}

const SmallTransferCard = ({ transfer, onClick }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { setPreviousRoute } = usePreviousRouteStore()
	const { driver } = useDriver(transfer.driverId)
	const [showFullOrigin, setShowFullOrigin] = useState<boolean>(false)
	const [showFullDestination, setShowFullDestination] = useState<boolean>(false)

	const { getRouteColor } = useRouteColors()

	if (!transfer) return null

	const handleClick = () => {
		setPreviousRoute(window.location.pathname)
		if (onClick) {
			onClick()
		} else {
			redirectToTransferDetails()
		}
	}

	const redirectToTransferDetails = () => {
		navigate(`${TRANSFER_URL}/${transfer.id}`)
	}

	const isStatsLastTransfersResponse = (
		transfer: StatsLastTransfersResponse | Transfer
	): transfer is StatsLastTransfersResponse => {
		return (transfer as StatsLastTransfersResponse).driver !== undefined
	}

	const driverName: StatsLastTransfersResponse['driver'] | undefined = isStatsLastTransfersResponse(
		transfer
	)
		? transfer.driver
		: driver?.name

	return (
		<Card
			role='button'
			className='small-transfer-card'
			onClick={handleClick}
			style={{
				borderLeftColor: getRouteColor(transfer.route)
			}}>
			<HeaderTransferDetails
				transfer={transfer}
				driverId={transfer.driverId}
				driver={driverName}
				date={dateToSpanish(new Date(transfer.date))}
				time={timeToSpanish(transfer.time)}
				backgroundColor={'transparent'}
			/>

			<main className='small-transfer-card-main'>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span
							className={`small-transfer-card-main-item-value ${
								showFullOrigin ? 'expanded' : emptyString
							}`}>
							{transfer.originAddress}
						</span>
						{transfer.originAddress.length > 30 && (
							<button
								className='show-more-btn'
								onClick={(e) => {
									e.stopPropagation()
									setShowFullOrigin(!showFullOrigin)
								}}>
								{showFullOrigin ? t('general.see_less') : t('general.see_more')}
							</button>
						)}
					</div>
				</div>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span
							className={`small-transfer-card-main-item-value ${
								showFullDestination ? 'expanded' : emptyString
							}`}>
							{transfer.destinationAddress}
						</span>
						{transfer.destinationAddress.length > 30 && (
							<button
								className='show-more-btn'
								onClick={(e) => {
									e.stopPropagation()
									setShowFullDestination(!showFullDestination)
								}}>
								{showFullDestination ? t('general.see_less') : t('general.see_more')}
							</button>
						)}
					</div>
				</div>
			</main>
		</Card>
	)
}

export default SmallTransferCard
