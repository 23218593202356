import { AppLauncher } from '@capacitor/app-launcher'
import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { CopyIcon, PhoneIcon, SMSIcon, WhatsappIcon } from 'assets/svgs'
import ThreeDotsModal from 'components/elements/ThreeDotsModal/ThreeDotsModal'
import { SEND_WHATSAPP } from 'constants/routes'
import parsePhoneNumber, { CountryCode, E164Number } from 'libphonenumber-js'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	phone: string
}

const TransferDetailsPhoneModal = ({ isOpen, setIsOpen, phone }: Props) => {
	const { t } = useTranslation()

	const normalizePhoneNumber = (phone: string, defaultCountryCode = 'ES', removePlus = false) => {
		try {
			const phoneNumber = parsePhoneNumber(phone, defaultCountryCode as CountryCode)

			if (!phoneNumber?.isValid()) {
				console.warn('Número de teléfono inválido:', phone)
				return phone
			}

			let formattedPhone: string = phoneNumber.number as E164Number

			if (removePlus) {
				formattedPhone = String(formattedPhone).replace('+', emptyString)
			}

			return formattedPhone
		} catch (error) {
			console.error('Error al procesar el número de teléfono:', error)
			return phone
		}
	}

	const handleCall = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const normalizedPhone = normalizePhoneNumber(phone)

		try {
			const isAvailable = await AppLauncher.canOpenUrl({ url: `tel://${normalizedPhone}` })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `tel://${normalizedPhone}` })
			} else {
				console.error('No se puede abrir la aplicación de llamadas')
			}
		} catch (error) {
			console.error('Error al intentar llamar:', error)
		}
		setIsOpen(false)
	}

	const handleSMS = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const normalizedPhone = normalizePhoneNumber(phone)

		try {
			const isAvailable = await AppLauncher.canOpenUrl({ url: `sms://${normalizedPhone}` })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `sms://${normalizedPhone}` })
			} else {
				console.error('No se puede abrir la aplicación de SMS')
			}
		} catch (error) {
			console.error('Error al intentar enviar SMS:', error)
		}
		setIsOpen(false)
	}

	const handleWhatsApp = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const normalizedPhone = normalizePhoneNumber(phone, '34', true)

		const isPlatformWeb = Capacitor.getPlatform() === 'web'
		if (isPlatformWeb) {
			window.open(`${SEND_WHATSAPP}${normalizedPhone}`, '_blank')
		} else {
			const isAvailable = await AppLauncher.canOpenUrl({ url: 'whatsapp://' })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `whatsapp://send?phone=${normalizedPhone}` })
			} else {
				window.open(`${SEND_WHATSAPP}${normalizedPhone}`, '_system')
			}
		}
		setIsOpen(false)
	}

	const handleCopyToClipboard = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const normalizedPhone = normalizePhoneNumber(phone)

		await Clipboard.write({
			string: normalizedPhone
		})
		setIsOpen(false)
	}

	const menuItems = useMemo(() => {
		return [
			{
				label: `${t('transfer_details.phone_modal.copy_number')} '${phone}'`,
				href: '#',
				icon: <CopyIcon />,
				onClick: handleCopyToClipboard
			},
			{
				label: `${t('transfer_details.phone_modal.call')} '${phone}'`,
				href: `tel:${phone}`,
				icon: <PhoneIcon />,
				onClick: handleCall
			},
			{
				label: t('transfer_details.phone_modal.send_sms'),
				href: `sms:${phone}`,
				icon: <SMSIcon />,
				onClick: handleSMS
			},
			{
				label: t('transfer_details.phone_modal.send_whatsapp'),
				href: '#',
				icon: <WhatsappIcon />,
				onClick: handleWhatsApp
			}
		]
	}, [phone])

	return <ThreeDotsModal isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} />
}

export default TransferDetailsPhoneModal
