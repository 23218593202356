import { Capacitor } from '@capacitor/core'
import { DatetimePicker } from '@capawesome-team/capacitor-datetime-picker'
import InputButton from 'components/elements/InputButton/InputButton'
import { Modal } from 'components/elements/Modal/Modal'
import { useModal } from 'hooks/useModal'
import { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import { handleGeneralKeyboardEvent } from 'utils/functions'
import './Input.css'

interface Props {
	value: string | undefined
	id: string
	label: string
	onSelect: (time: string | undefined) => void
	error: string | undefined
	disabled?: boolean
}
const defaultTime = '12:00'

const InputTime = forwardRef<HTMLElement, Props>(
	({ value, onSelect, id, label, error, disabled = false }: Props, ref) => {
		const { t } = useTranslation()
		const [modalIsVisible, toggleModalVisibility] = useModal()
		const [valueSelected, onChangeSelected] = useState<string | undefined>(value)

		const timeRefs = useRef<(HTMLDivElement | null)[]>([])

		const times = Array.from({ length: 24 * 12 }, (_, i) => {
			const hour = Math.floor(i / 12)
			const minute = (i % 12) * 5
			const hourString = hour.toString().padStart(2, '0')
			const minuteString = minute.toString().padStart(2, '0')
			return `${hourString}:${minuteString}`
		})

		const handleTimeSelect = (time: string | undefined) => {
			if (!time || !time.length) return
			onChangeSelected(time)
			onSelect(time)
			toggleModalVisibility()
		}

		const handleNativeTimePicker = async () => {
			const currentDate = new Date()
			if (valueSelected) {
				const [hours, minutes] = valueSelected.split(':')
				currentDate.setHours(parseInt(hours, 10), parseInt(minutes, 10))
			}
			const result = await DatetimePicker.present({
				mode: 'time',
				value: currentDate.toISOString(),
				theme: 'light',
				cancelButtonText: t('general.cancel'),
				doneButtonText: t('general.accept')
			})
			if (result.value) {
				const selectedDate = new Date(result.value)
				const localHours = selectedDate.getUTCHours().toString().padStart(2, '0')
				const localMinutes = selectedDate.getUTCMinutes().toString().padStart(2, '0')

				const timeString = `${localHours}:${localMinutes}`
				onChangeSelected(timeString)
				onSelect(timeString)
			}
		}

		const handleButtonClick = () => {
			if (!disabled) {
				if (Capacitor.getPlatform() !== 'web') {
					handleNativeTimePicker()
				} else {
					toggleModalVisibility()
				}
			}
		}

		useEffect(() => {
			onChangeSelected(value)
		}, [value])

		useEffect(() => {
			if (modalIsVisible) {
				const timeToScroll = valueSelected || defaultTime
				const index = times.indexOf(timeToScroll)
				if (!timeRefs.current[index]) return
				if (index >= 0 && index < times.length && timeRefs.current[index] instanceof HTMLElement) {
					timeRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
			}
		}, [modalIsVisible, valueSelected])

		return (
			<>
				{!modalIsVisible ? (
					<div className={`input-time ${error ? 'error' : emptyString}`}>
						<label htmlFor={id}>{label}</label>
						<InputButton
							id={id}
							type='time'
							onClick={handleButtonClick}
							placeholder='HH:MM'
							text={value}
							error={error}
							disabled={disabled}
							ref={ref as RefObject<HTMLButtonElement>}
						/>
						{error && <span className='input-error-message'>{error}</span>}
					</div>
				) : null}

				{/* Sólo mostramos el modal en la web */}
				{Capacitor.getPlatform() === 'web' && (
					<Modal
						isVisible={modalIsVisible}
						toggleVisibility={toggleModalVisibility}
						isFull
						showCloseButton={false}>
						<div className='hour-picker-container'>
							{times.map((time, index) => (
								<div
									key={index}
									ref={(el) => {
										timeRefs.current[index] = el
									}}
									className={valueSelected === time ? 'selected' : emptyString}
									onClick={() => handleTimeSelect(time)}
									onKeyDown={handleGeneralKeyboardEvent(() => handleTimeSelect(time))}>
									{time}
								</div>
							))}
						</div>
					</Modal>
				)}
			</>
		)
	}
)

export default InputTime
