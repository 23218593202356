import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import SelectDateRangeOptions from 'components/elements/SelectOptions/SelectDateRangeOptions'
import { WORK_LOG_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { WorkLogExport } from 'models/WorkLog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDateRange } from 'utils/dateUtils'
import WorkLogTable from '../WorkLogTable/WorkLogTable'
import WorkLogExportModal from './ExportWorkLogModal'

const WorkLogDriversHistory = () => {
	const { t } = useTranslation()
	const [filter, setFilter] = useState<WorkLogExport>(getDateRange(0))
	const [exportModalIsVisible, toggleExportModalVisibility] = useModal()

	return (
		<FormLayout
			title={t('work-log.work-logs')}
			leftButton={<ButtonBack url={WORK_LOG_URL} />}
			footer={
				<ButtonComponent fullWidth onClick={toggleExportModalVisibility}>
					{t('work-log.export-logs')}
				</ButtonComponent>
			}>
			<div className='work-log-container'>
				<SelectDateRangeOptions
					onSelect={(range) => {
						setFilter((prevFilter) => ({
							...prevFilter,
							...range
						}))
					}}
				/>
				<WorkLogTable showOtherUsers noPaginate filter={filter} perPage={Infinity} />
				<WorkLogExportModal
					isVisible={exportModalIsVisible}
					toggleModalVisibility={toggleExportModalVisibility}
					filter={filter}
				/>
			</div>
		</FormLayout>
	)
}

export default WorkLogDriversHistory
