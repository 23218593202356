import ButtonComponent from 'components/elements/Button/Button'
import FormInput from 'components/elements/FormInput/FormInput'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import SelectDriverOptions from 'components/elements/SelectOptions/SelectDriverOptions'
import SelectProviderOptions from 'components/elements/SelectOptions/SelectProviderOptions'
import SelectUserOptions from 'components/elements/SelectOptions/SelectUserOptions'
import SelectVehicleOptions from 'components/elements/SelectOptions/SelectVehicleOptions'
import { useFormik } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { isUndefined } from 'lodash'
import { TransferFilterForm } from 'models/Transfer'
import { UserRoles } from 'models/User'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'stores/useUserStore'
import { currentUserIsAdmin } from 'utils/currentUser'
import { getFormikError, handleCheckboxChange } from 'utils/formikUtils'

interface Props {
	isVisible: boolean
	toggleModalVisibility: () => void
	onSelect: (filter: TransferFilterForm) => void
}

const FilterCostsModal: React.FC<Props> = (props) => {
	const { isVisible, toggleModalVisibility, onSelect } = props
	const userState = useUserStore()
	const curretUser = userState.currentUser
	const isAdmin = currentUserIsAdmin()

	const { t } = useTranslation()

	const formik = useFormik<TransferFilterForm>({
		initialValues: {
			driverIds: null,
			providerIds: null,
			isPaid: undefined,
			vehicleIds: null,
			userIds: null
		},
		onSubmit: (values) => {
			onSelect(values)
			toggleModalVisibility()
		}
	})

	return (
		<ModalFormLayout
			isVisible={isVisible}
			toggleVisibility={toggleModalVisibility}
			title={t('historical.filter_modal.title')}
			footer={
				<>
					<ButtonComponent fullWidth variant='secondary' onClick={formik.resetForm}>
						{t('general.reset')}
					</ButtonComponent>
					<ButtonComponent fullWidth variant='primary' onClick={formik.handleSubmit}>
						{t('general.apply_changes')}
					</ButtonComponent>
				</>
			}>
			<FormSectionLayout>
				{curretUser?.userTypeId !== UserRoles.driver ? (
					<SelectDriverOptions
						label={t('historical.filter_modal.label.driver')}
						onSelect={(driverIds) => formik.setFieldValue('driverIds', driverIds)}
						selectedId={formik.values.driverIds ?? null}
						error={getFormikError(formik, 'driverIds')}
						isLabelOptional
						isMultiple
					/>
				) : null}

				{curretUser?.userTypeId !== UserRoles.provider ? (
					<SelectProviderOptions
						label={t('historical.filter_modal.label.provider')}
						onSelect={(providerIds) => formik.setFieldValue('providerIds', providerIds)}
						selected={formik.values.providerIds ?? null}
						error={getFormikError(formik, 'providerIds')}
						isLabelOptional
						isMultiple
					/>
				) : null}

				<FormInput label={t('historical.filter_modal.label.status')} isLabelOptional>
					<div className='historical-filter-is-paid'>
						<CheckboxInput
							label={t('general.all_paid')}
							id='isPaid'
							onChange={handleCheckboxChange<TransferFilterForm>(formik, 'isPaid', true)}
							checked={isUndefined(formik.values.isPaid) ? undefined : formik.values.isPaid}
						/>
						<CheckboxInput
							label={t('general.is_not_paid')}
							id='isNotPaid'
							onChange={handleCheckboxChange<TransferFilterForm>(formik, 'isPaid', false)}
							checked={isUndefined(formik.values.isPaid) ? undefined : !formik.values.isPaid}
						/>
					</div>
				</FormInput>
				<SelectVehicleOptions
					label={t('historical.filter_modal.label.plate')}
					onSelect={(vehicleId) => formik.setFieldValue('vehicleIds', vehicleId)}
					selectedId={formik.values.vehicleIds ?? null}
					error={getFormikError(formik, 'vehicleIds')}
					isLabelOptional
					isMultiple
					allowInactive
				/>
				{isAdmin ? (
					<SelectUserOptions
						label={t('historical.filter_modal.label.user')}
						onSelect={(user) => formik.setFieldValue('userIds', user)}
						selectedId={formik.values.userIds ?? null}
						error={getFormikError(formik, 'userIds')}
						isLabelOptional
						isMultiple
					/>
				) : null}
			</FormSectionLayout>
		</ModalFormLayout>
	)
}

export default FilterCostsModal
