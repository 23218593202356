import { CaretCircleLeft, CaretCircleRight } from 'assets/svgs'
import InputDate from 'components/elements/Input/InputDate'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { dateToSQL, nextDateSqlFormat, previousDateSqlFormat } from 'utils/dateUtils'
import './DayNavigationBlock.css'

interface Props {
	date: Date
}

export const DayNavigationBlock: FC<Props> = (props) => {
	const { date } = props
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleDate = (day: Date | undefined) => {
		if (!day) return
		navigate(`${DAILY_URL}/${dateToSQL(day)}`)
	}

	const nextDay = nextDateSqlFormat(new Date(date))
	const previousDay = previousDateSqlFormat(new Date(date))

	return (
		<div className='day-navigator'>
			<InputDate id='calendar' value={date} onSelect={(day) => handleDate(day)} showWeekDay />
			<div className='dates-buttons'>
				<Link to={`${DAILY_URL}/${previousDay}`}>
					<CaretCircleLeft /> {t('general.previous_day')}
				</Link>
				<Link to={`${DAILY_URL}/${nextDay}`}>
					{t('general.next_day')} <CaretCircleRight />
				</Link>
			</div>
		</div>
	)
}
