import { AppLauncher } from '@capacitor/app-launcher'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import {
	AirplaneTakeoffIcon,
	ArrowSquareOutIcon,
	MapPinIcon,
	MapPinLineIcon,
	StopIcon,
	UserFilledIcon
} from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import ExtraSeatCategoryTag from 'components/elements/CategoryTag/ExtraSeatCategoryTag'
import FomentoCategoryTag from 'components/elements/CategoryTag/FomentoCategoryTag'
import RequestShareTag from 'components/elements/CategoryTag/RequestShareTag'
import SharedTransferTag from 'components/elements/CategoryTag/SharedTransferTag'
import StartEndCategoryTag from 'components/elements/CategoryTag/StartEndCategoryTag'
import TransferReviewedTag from 'components/elements/CategoryTag/TransferReviewedTag'
import ColorIndicator from 'components/elements/ColorIndicator/ColorIndicator'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import DetailsCardRow from 'components/elements/DetailsCard/DetailsCardRow'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import Loading from 'components/elements/Loading/Loading'
import MapLink from 'components/elements/MapLink/MapLink'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import PaidTag from 'components/elements/PaidTag/PaidTag'
import TransferTimer from 'components/elements/TransferTimer/TransferTimer'
import colors from 'constants/colors'
import { CURRENCY_SYMBOL, NO_VALUE } from 'constants/common'
import { DAILY_URL, FLIGHT_RADAR } from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useRouteColors from 'hooks/useRouteColors'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { useTransferReview } from 'hooks/useTransferReview'
import { useUpdateTransfer } from 'hooks/useUpdateTransfer'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { Permissions } from 'models/Configurations'
import { RouteTypeName, TransferPost, TypeTransfer } from 'models/Transfer'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import FomentoButton from './Buttons/FomentoButton'
import StartEndHour from './Buttons/StartEndHour'
import './TransferDetails.css'
import TransferDetailsDrawerMenu from './TransferDetailsDrawerMenu'
import TransferDetailsPhoneModal from './TransferDetailsPhoneModal'

const TransferDetails = () => {
	const { transferId } = useParams()
	const {
		transfer,
		driver,
		fomento,
		accepted,
		pendings,
		hasStart,
		hasEnd,
		startEndDetails,
		provider,
		user,
		vehicle,
		startTransfer,
		endTransfer,
		loading,
		isLoadingStartEndTransfers
	} = useTransferDetails(Number(transferId))

	const { updateTransfer } = useUpdateTransfer()

	const { adminTransferReviews, isLoadingAdminTransferReviews } = useTransferReview(
		Number(transferId)
	)

	const [menuIsOpen, setMenuIsOpen] = useState(false)
	const [finishTransferModalIsVisible, setFinishTransferModalIsVisible] = useState(false)
	const [openPhoneModal, setOpenPhoneModal] = useState(false)
	const { isConfigurationActive } = useConfigurations()
	const isAdmin = currentUserIsAdmin()
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const isReviewed = params.get('markAsReviewedAdmin')

		if (isReviewed === 'true' && isAdmin) {
			const transferPost: Partial<TransferPost> = { type: TypeTransfer.list }

			updateTransfer({ transfer: transferPost, id: Number(transferId) }).then(() => {
				toast.success(t('with_out_revision_transfer.reviewed'))
				params.delete('markAsReviewedAdmin')
				navigate(
					{
						pathname: location.pathname,
						search: params.toString()
					},
					{ replace: true }
				)
			})
		}
	}, [location.search])

	const startHour = useMemo(() => {
		return startEndDetails?.createdAt ? new Date(startEndDetails?.createdAt) : null
	}, [startEndDetails?.createdAt])

	const endHour = useMemo(() => {
		return startEndDetails?.finishedAt ? new Date(startEndDetails?.finishedAt) : null
	}, [startEndDetails?.finishedAt])

	const { t } = useTranslation()

	const { getDegradedColor, getRouteColor } = useRouteColors()

	if (loading || isLoadingStartEndTransfers || isLoadingAdminTransferReviews)
		return <Loading fullscreen />
	if (!transfer || !driver || !provider || !user) return null

	const routeTranslation = t(`transfer.route.${transfer.route}`)

	const closeFinishTransferModal = () => setFinishTransferModalIsVisible(false)

	const closeMenu = () => setMenuIsOpen(false)

	const statusSectionIsVisible =
		hasStart || hasEnd || pendings.length || accepted || adminTransferReviews.length

	const openFlight = async () => {
		if (!transfer?.flight) return
		const flightFormatted = transfer?.flight.replace(/\s/g, '')
		const url = `${FLIGHT_RADAR}/${flightFormatted}/`
		if (Capacitor.isNativePlatform()) {
			const isAvailable = await AppLauncher.canOpenUrl({ url })
			if (isAvailable) {
				await AppLauncher.openUrl({ url })
			} else {
				await Browser.open({ url })
			}
		} else {
			window.open(url, '_blank')
		}
	}

	return (
		<>
			<FormLayout
				title={t('transfer.details.h1')}
				leftButton={<ButtonBack url={`${DAILY_URL}/${transfer.date}`} />}
				rightButton={
					<ButtonComponent onClick={() => setMenuIsOpen(true)} variant='text'>
						<strong>{t('general.options')}</strong>
					</ButtonComponent>
				}
				footer={
					<div className='transfer-details-footer'>
						{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) && (
							<FomentoButton transferId={Number(transferId)} fomento={fomento} />
						)}
						{isConfigurationActive(Permissions.START_END) && !isAdmin && (
							<StartEndHour
								startHour={startHour}
								endHour={endHour}
								onEndHour={() => setFinishTransferModalIsVisible(true)}
								onStartHour={async () => await startTransfer(Number(transferId))}
							/>
						)}
					</div>
				}>
				{startHour && (
					<FormSectionLayout noDivider>
						<TransferTimer startHour={startHour} endHour={endHour} />
					</FormSectionLayout>
				)}
				<FormSectionLayout
					title={routeTranslation}
					titleIcon={<ColorIndicator color={getRouteColor(transfer.route)} />}
					noDivider>
					<HeaderTransferDetails
						transfer={transfer}
						date={dateToSpanish(new Date(transfer.date))}
						driverId={driver.id}
						driver={driver?.name}
						fomento={isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) ? fomento : null}
						time={timeToSpanish(transfer.time)}
						backgroundColor={getDegradedColor(transfer.route)}
					/>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.origin')}>
							<div className='transfer-details-value-with-icon'>
								<MapPinIcon />
								<span className='transfer-details-value-with-icon-text body1'>
									{transfer.originAddress}
								</span>
								<MapLink address={transfer.originAddress} />
							</div>
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.destination')}>
							<span className='transfer-details-value-with-icon'>
								<MapPinLineIcon />
								{transfer.destinationAddress}
								<MapLink address={transfer.destinationAddress} />
							</span>
						</DetailsCardItem>
						{transfer.flight && transfer.route === RouteTypeName.arrival ? (
							<DetailsCardItem title={t('transfer.flight')}>
								<span
									className={`transfer-details-value-with-icon open-flight`}
									onClick={openFlight}>
									<AirplaneTakeoffIcon />
									{transfer.flight}
									<ArrowSquareOutIcon />
								</span>
							</DetailsCardItem>
						) : null}
					</DetailsCard>
					{isConfigurationActive(Permissions.SHOW_EXTERNAL_ID) && (
						<DetailsCard>
							<DetailsCardRow>
								<DetailsCardItem title={t('transfer.booking_number')}>
									{transfer.externalId ? transfer.externalId : NO_VALUE}
								</DetailsCardItem>
							</DetailsCardRow>
						</DetailsCard>
					)}
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.passengers')}>
								<span className='transfer-details-value-with-icon' data-testid='passengers'>
									<UserFilledIcon color={colors.black} />
									{transfer.passengers}
								</span>
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.extras')}>
								<ExtraSeatCategoryTag transfer={transfer} />
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.price')}>
								{transfer.price ? `${transfer.price} ${CURRENCY_SYMBOL}` : NO_VALUE}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.status')}>
								<PaidTag paid={transfer.isPaid} />
							</DetailsCardItem>
							{transfer.paymentMethod && (
								<DetailsCardItem title={t('general.payment_method')}>
									<PaidTag paymentMethod={transfer.paymentMethod} />
								</DetailsCardItem>
							)}
						</DetailsCardRow>
					</DetailsCard>
					{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) && fomento !== null && (
						<DetailsCard>
							<DetailsCardRow>
								<DetailsCardItem title={t('fomento.title')}>
									<FomentoCategoryTag fomento={fomento} />
								</DetailsCardItem>
							</DetailsCardRow>
						</DetailsCard>
					)}
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.toll')}>
								{transfer.tollWithVat.toFixed(2)} {CURRENCY_SYMBOL}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.commission')}>
								{transfer.commission} {CURRENCY_SYMBOL}
							</DetailsCardItem>
							{transfer.otherCommission ? (
								<DetailsCardItem title={t('transfer.other_commission')}>
									{transfer.otherCommission} {CURRENCY_SYMBOL}
								</DetailsCardItem>
							) : null}
						</DetailsCardRow>
					</DetailsCard>
					{statusSectionIsVisible ? (
						<DetailsCard>
							<DetailsCardItem title={t('transfer.status')}>
								<div className='transfer-details-status'>
									<StartEndCategoryTag hasEnd={hasEnd} hasStart={hasStart} />
									<RequestShareTag pendings={pendings} />
									<SharedTransferTag accepted={accepted} />
									<TransferReviewedTag transferReviews={adminTransferReviews} />
								</div>
							</DetailsCardItem>
						</DetailsCard>
					) : null}
				</FormSectionLayout>
				<FormSectionLayout title={t('transfer.client')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.client')}>{transfer.clientName}</DetailsCardItem>
						<DetailsCardItem title={t('transfer.phone')}>
							<span
								className={transfer.phone ? 'open-phone' : emptyString}
								onClick={() => transfer.phone && setOpenPhoneModal(true)}>
								{transfer.phone ? transfer.phone : NO_VALUE}

								{transfer.phone ? <ArrowSquareOutIcon /> : null}
							</span>
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.email')}>
							{transfer.email ? transfer.email : NO_VALUE}
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.observations')}>
							{transfer.observations ? transfer.observations : NO_VALUE}
						</DetailsCardItem>
						{isConfigurationActive(Permissions.PRIVATE_NOTES) ? (
							<DetailsCardItem title={t('transfer.private_observations')}>
								{transfer.privateObservations ? transfer.privateObservations : NO_VALUE}
							</DetailsCardItem>
						) : null}
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('transfer.resources')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.driver')}>{driver.name}</DetailsCardItem>
						{vehicle && (
							<DetailsCardItem title={t('transfer.vehicle')}>
								{vehicle.brand} {vehicle.model} {vehicle.plate}
							</DetailsCardItem>
						)}
						<DetailsCardItem title={t('transfer.provider')}>{provider.name}</DetailsCardItem>
						<DetailsCardItem title={t('transfer.employee')}>
							{user.name} {user.lastName}
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.registrationDate')}>
							{dateToSpanish(new Date(transfer.registrationDate))}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
			</FormLayout>
			<TransferDetailsDrawerMenu
				transferId={Number(transferId)}
				menuIsOpen={menuIsOpen}
				closeMenu={closeMenu}
			/>
			<ModalActions
				icon={<StopIcon />}
				title={t('transfer.finish_transfer_modal.title')}
				description={t('transfer.finish_transfer_modal.description')}
				primaryButton={{
					text: t('transfer.finish_transfer_modal.primary_button'),
					onClick: async () => {
						await endTransfer(Number(transferId))
						closeFinishTransferModal()
					}
				}}
				secondaryButton={{
					text: t('transfer.finish_transfer_modal.secondary_button'),
					onClick: closeFinishTransferModal
				}}
				isVisible={finishTransferModalIsVisible}
				onClose={closeFinishTransferModal}
			/>
			<TransferDetailsPhoneModal
				isOpen={openPhoneModal}
				setIsOpen={setOpenPhoneModal}
				phone={transfer.phone}
			/>
		</>
	)
}

export default TransferDetails
