import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dailyTransfersQueryKey, transferQueryKey } from 'constants/reactQueryKeys'
import { TransferPost } from 'models/index'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateTransferService } from 'services/transfers'

export const useUpdateTransfer = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	//UPDATE TRANSFER
	const { mutateAsync: updateTransfer } = useMutation({
		mutationFn: (data: { transfer: Partial<TransferPost>; id: number }) =>
			updateTransferService(data.id, data.transfer),
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [transferQueryKey, data.id]
			})
			queryClient.invalidateQueries({
				queryKey: [dailyTransfersQueryKey]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_update'))
		}
	})

	return {
		updateTransfer,
		errorMessage
	}
}
