import { useQuery } from '@tanstack/react-query'
import { workLogQueryKey } from 'constants/reactQueryKeys'
import { getUserId } from 'services/utils'
import { getWorkLogByDateService } from 'services/workLog'

const useWorkLogDetail = (date?: string, userId = getUserId()) => {
	// GET WORKLOG BY DATE
	const { data: workLogDetail, isLoading: isLoadingWorkLogDetail } = useQuery({
		queryKey: [workLogQueryKey, date, userId],
		queryFn: date ? () => getWorkLogByDateService(date, userId) : undefined,
		enabled: !!date
	})

	return { workLogDetail: workLogDetail || [], isLoadingWorkLogDetail }
}

export default useWorkLogDetail
