import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { WORK_LOG_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import WorkLogTable from '../WorkLogTable/WorkLogTable'

const WorkLogHistory = () => {
	const { t } = useTranslation()
	return (
		<FormLayout
			title={t('work-log.work-log-history')}
			leftButton={<ButtonBack url={WORK_LOG_URL} />}>
			<div className='work-log-container'>
				<WorkLogTable />
			</div>
		</FormLayout>
	)
}

export default WorkLogHistory
