import NotePencilIcon from 'assets/svgs/NotePencilIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import ThreeDotsModal from 'components/elements/ThreeDotsModal/ThreeDotsModal'
import { WORK_LOG_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import useWorkLog from 'hooks/useWorkLog'
import { WorkLogItem } from 'models/WorkLog'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	onEditClick?: () => void
	savePreviousRoute?: boolean
	workLogId: WorkLogItem['id']
}

const WorkLogModal = ({
	isOpen,
	setIsOpen,
	onEditClick,
	savePreviousRoute = false,
	workLogId
}: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const { setPreviousRoute } = usePreviousRouteStore()

	const { deleteWorkLog } = useWorkLog()

	const menuItems = useMemo(
		() => [
			{
				label: t('work-log.edit-work-log'),
				href: '#',
				icon: <NotePencilIcon />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					if (savePreviousRoute) {
						setPreviousRoute(window.location.pathname)
					}
					if (onEditClick) {
						onEditClick()
					}
					setIsOpen(false)
				}
			},
			{
				label: t('general.delete'),
				href: '#',
				icon: <TrashIcon />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					toggleDeleteModal()
				}
			}
		],
		[onEditClick, setIsOpen, savePreviousRoute, setPreviousRoute]
	)

	const handleDeleteClick = async () => {
		if (!workLogId) return
		await deleteWorkLog({
			id: workLogId
		})
		toggleDeleteModal()
		setIsOpen(false)
		navigate(WORK_LOG_URL)
	}

	return (
		<>
			<ThreeDotsModal isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} />
			<ModalActions
				type='error'
				title={t('work-log.delete-work-log')}
				description={t('work-log.confirmation-delete')}
				isVisible={isDeleteModalVisible}
				onClose={toggleDeleteModal}
				primaryButton={{
					text: t('general.remove'),
					onClick: handleDeleteClick
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: toggleDeleteModal
				}}
			/>
		</>
	)
}

export default WorkLogModal
