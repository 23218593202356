import { TrashIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import InputTime from 'components/elements/Input/InputTime'
import Loading from 'components/elements/Loading/Loading'
import TextArea from 'components/elements/TextArea/TextArea'
import { WORK_LOG_URL } from 'constants/routes'
import useWorkLog from 'hooks/useWorkLog'
import useWorkLogDetail from 'hooks/useWorkLogDetail'
import useWorkLogsUtils from 'hooks/useWorkLogsUtils'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { WorkLog, WorkLogTimeRange } from 'models/WorkLog'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getUserId } from 'services/utils'
import { emptyString } from 'utils/common'
import { formatDateToSpanish, parseISODate } from 'utils/dateUtils'
import WorkLogModal from '../WorkLogTable/WorkLogModal'
import './WorkLogDetail.css'

const MAX_TIME_RANGES = 3
const ZERO_H = '0h'
const DOUBLE_ZERO = ':00'

const WorkLogDetail = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { date, userId } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()

	const { createWorkLog, updateWorkLog } = useWorkLog()
	const { workLogDetail, isLoadingWorkLogDetail } = useWorkLogDetail(date, +userId!)
	const { formatTimeWithoutSeconds, calculateTimeDifference } = useWorkLogsUtils()

	const [timeRanges, setTimeRanges] = useState<WorkLogTimeRange[]>([])
	const [showModal, setShowModal] = useState(false)
	const [observations, setObservations] = useState(emptyString)
	const [dataLoaded, setDataLoaded] = useState(false)
	const [selectedWorkLog, setSelectedWorkLog] = useState<WorkLog | null>(null)
	const [timeErrors, setTimeErrors] = useState<{ [key: string]: boolean }>({})
	const [errorMessage, setErrorMessage] = useState<string>('')

	const isEditing = searchParams.get('edit') === 'true'
	const isRegistering = searchParams.get('register') === 'true'
	const isFormEditable = isEditing || isRegistering

	useEffect(() => {
		if (isRegistering && !dataLoaded) {
			setTimeRanges([
				{
					id: `time-range-${Date.now()}`,
					start: emptyString,
					end: emptyString,
					total: ZERO_H
				}
			])
			setObservations(emptyString)
			setDataLoaded(true)
		} else if (!isRegistering && !dataLoaded && workLogDetail && workLogDetail.length) {
			const ranges = workLogDetail.map((workLog) => {
				const start = formatTimeWithoutSeconds(workLog.startTime)
				const end = workLog.endTime ? formatTimeWithoutSeconds(workLog.endTime) : emptyString
				const total = calculateTimeDifference(start, end)
				return {
					id: `time-range-${workLog.id}`,
					start,
					end,
					total,
					workLogId: workLog.id
				}
			})

			setTimeRanges(ranges)
			const uniqueObservations = [...new Set(workLogDetail.map((wl) => wl.observations))].filter(
				Boolean
			)
			setObservations(uniqueObservations.join('\n') || emptyString)
			setDataLoaded(true)
			if (workLogDetail.length > 0) {
				setSelectedWorkLog(workLogDetail[0])
			}
		}
	}, [isRegistering, dataLoaded, workLogDetail])

	const handleEditClick = () => {
		setShowModal(false)
		setSearchParams({ edit: 'true' })
	}
	const validateForm = () => {
		if (!date || !/^\d{4}-\d{2}-\d{2}$/.test(date)) return true
		const hasStartTime = timeRanges.some((range) => range.start && range.start !== emptyString)
		if (!hasStartTime) return true
		if (Object.keys(timeErrors).length > 0) return true
		return false
	}

	const isEndTimeBeforeStartTime = (startTime: string, endTime: string): boolean => {
		if (!startTime || startTime === emptyString || !endTime || endTime === emptyString) {
			return false
		}
		const [startHour, startMinute] = startTime.split(':').map(Number)
		const [endHour, endMinute] = endTime.split(':').map(Number)
		if (endHour < startHour) return true
		if (endHour === startHour && endMinute < startMinute) return true
		return false
	}

	const handleSave = async () => {
		if (!date) return
		if (isEditing) {
			const existingRanges = timeRanges.filter((range) => range.workLogId)
			const newRanges = timeRanges.filter(
				(range) => !range.workLogId && range.start && range.start !== emptyString
			)
			if (existingRanges.length > 0) {
				await Promise.all(
					existingRanges.map((timeRange) => {
						const hasEnd = timeRange.end && timeRange.end !== emptyString
						return updateWorkLog({
							id: timeRange.workLogId!,
							data: {
								startDay: date,
								startTime: timeRange.start + DOUBLE_ZERO,
								endDay: date,
								endTime: hasEnd ? timeRange.end + DOUBLE_ZERO : null,
								observations
							},
							userId: userId ? +userId : getUserId()
						})
					})
				)
			}
			if (newRanges.length > 0) {
				await Promise.all(
					newRanges.map((range) => {
						const hasEnd = range.end && range.end !== emptyString
						return createWorkLog({
							startDay: date,
							startTime: range.start + DOUBLE_ZERO,
							endDay: date,
							endTime: hasEnd ? range.end + DOUBLE_ZERO : null,
							observations
						})
					})
				)
			}
			navigate(WORK_LOG_URL)
		} else if (isRegistering) {
			await Promise.all(
				timeRanges
					.filter((range) => range.start && range.start !== emptyString)
					.map((range) => {
						const hasEnd = range.end && range.end !== emptyString
						return createWorkLog({
							startDay: date,
							startTime: range.start + DOUBLE_ZERO,
							endDay: date,
							endTime: hasEnd ? range.end + DOUBLE_ZERO : null,
							observations
						})
					})
			)
			navigate(WORK_LOG_URL)
		}
	}

	const getPageTitle = () => {
		if (isRegistering) return t('work-log.work-log-register')
		if (isEditing) return t('work-log.edit-work-log')
		return t('work-log.work-log-details')
	}

	const formatWorkLogDate = () => {
		if (date && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
			const parsedDate = parseISODate(date)
			if (parsedDate && !isNaN(parsedDate.getTime())) {
				return formatDateToSpanish(parsedDate)
			}
		}
		return formatDateToSpanish()
	}

	const handleTimeChange = (id: string, field: 'start' | 'end', value: string | undefined) => {
		if (!isFormEditable) return
		setTimeRanges((prevRanges) => {
			const updatedRanges = prevRanges.map((range) => {
				if (range.id === id) {
					const updatedRange = { ...range, [field]: value || emptyString }
					if (field === 'start' || field === 'end') {
						const startValue = field === 'start' ? value || emptyString : range.start
						const endValue = field === 'end' ? value || emptyString : range.end
						if (isEndTimeBeforeStartTime(startValue, endValue)) {
							setTimeErrors((prev) => ({
								...prev,
								[id]: true
							}))
							setErrorMessage(t('work-log.error-field'))
						} else {
							setTimeErrors((prev) => {
								const newErrors = { ...prev }
								delete newErrors[id]
								if (Object.keys(newErrors).length === 0) {
									setErrorMessage(emptyString)
								}
								return newErrors
							})
						}
						updatedRange.total = calculateTimeDifference(startValue, endValue)
					}
					return updatedRange
				}
				return range
			})
			return updatedRanges
		})
	}

	const isEmptyTimeRange = (range: WorkLogTimeRange) => {
		return (
			(!range.start || range.start === emptyString) && (!range.end || range.end === emptyString)
		)
	}

	const handleDeleteTimeRange = (id: string) => {
		if (!isFormEditable) return
		setTimeErrors((prev) => {
			const newErrors = { ...prev }
			delete newErrors[id]
			if (Object.keys(newErrors).length === 0) {
				setErrorMessage(emptyString)
			}
			return newErrors
		})

		setTimeRanges((prevRanges) => {
			if (prevRanges.length === 1) {
				return prevRanges.map((range) => {
					if (range.id === id) {
						return {
							...range,
							start: emptyString,
							end: emptyString,
							total: ZERO_H
						}
					}
					return range
				})
			}
			const targetRange = prevRanges.find((range) => range.id === id)
			if (targetRange && isEmptyTimeRange(targetRange)) {
				return prevRanges.filter((range) => range.id !== id)
			}
			return prevRanges.map((range) => {
				if (range.id === id) {
					return {
						...range,
						start: emptyString,
						end: emptyString,
						total: ZERO_H
					}
				}
				return range
			})
		})
	}

	const handleAddTimeRange = () => {
		if (!isFormEditable) return
		if (timeRanges.length >= MAX_TIME_RANGES) return
		const newId = `time-range-${Date.now()}`
		setTimeRanges((prevRanges) => [
			...prevRanges,
			{
				id: newId,
				start: emptyString,
				end: emptyString,
				total: ZERO_H
			}
		])
	}

	const shouldShowName = () => {
		if (workLogDetail.length) return workLogDetail[0].id !== getUserId()
		return false
	}

	return (
		<>
			<FormLayout
				title={getPageTitle()}
				leftButton={<ButtonBack url={WORK_LOG_URL} />}
				rightButton={
					!isFormEditable &&
					workLogDetail.length > 0 &&
					!isLoadingWorkLogDetail && (
						<ButtonComponent onClick={() => setShowModal(true)} variant='only-text'>
							<strong>{t('general.options')}</strong>
						</ButtonComponent>
					)
				}
				footer={
					isFormEditable && (
						<ButtonComponent
							onClick={handleSave}
							fullWidth
							variant='primary'
							disabled={validateForm()}>
							{t('general.save')}
						</ButtonComponent>
					)
				}>
				<div className='work-log-container'>
					<div className='work-log-section work-logs-details-section'>
						{isLoadingWorkLogDetail ? (
							<div className='work-log-details-loading'>
								<Loading hasText={false} />
							</div>
						) : (
							<>
								{shouldShowName() && (
									<div className='work-log-user-section'>
										<span>{t('work-log.user')}</span>
										<div className='user-name'>
											{workLogDetail[0].userName} {workLogDetail[0].userLastName}
										</div>
									</div>
								)}
								<div className='work-log-date-section'>
									<span>{formatWorkLogDate()}</span>
								</div>
								<div className='worklog-details-hours'>
									{timeRanges.map((timeRange) => (
										<div key={timeRange.id} className='worklog-details-hours-row'>
											<InputTime
												id={`start-time-${timeRange.id}`}
												label={t('work-log.start')}
												value={timeRange.start}
												onSelect={(value) => handleTimeChange(timeRange.id, 'start', value)}
												error={undefined}
												disabled={!isFormEditable}
											/>

											<InputTime
												id={`end-time-${timeRange.id}`}
												label={t('work-log.end')}
												value={timeRange.end}
												onSelect={(value) => handleTimeChange(timeRange.id, 'end', value)}
												error={timeErrors[timeRange.id] ? ' ' : undefined}
												disabled={!isFormEditable}
											/>

											<div className='total-hours-display'>
												<div className='total-hours-label'>{t('work-log.total')}</div>
												<div className='total-hours-value'>{timeRange.total}</div>
											</div>
											{isFormEditable && (
												<div
													className={`delete-time-range-button ${
														timeRanges.length === 1 || !isEmptyTimeRange(timeRange)
															? 'clear-action'
															: 'delete-action'
													}`}
													onClick={() => handleDeleteTimeRange(timeRange.id)}>
													<TrashIcon />
												</div>
											)}
										</div>
									))}
									{errorMessage && <div className='input-error-message'>{errorMessage}</div>}
									{isFormEditable && timeRanges.length < MAX_TIME_RANGES && (
										<div className='add-time-range-button' onClick={handleAddTimeRange}>
											+ {t('work-log.add-more')}
										</div>
									)}
								</div>
								<TextArea
									id={'observations'}
									label={t('work-log.observations')}
									valueSelected={observations}
									onChange={(e) => setObservations(e.target.value)}
									disabled={!isFormEditable}
								/>
							</>
						)}
					</div>
				</div>
			</FormLayout>
			{!isFormEditable && selectedWorkLog && (
				<WorkLogModal
					isOpen={showModal}
					setIsOpen={setShowModal}
					onEditClick={handleEditClick}
					savePreviousRoute={false}
					workLogId={selectedWorkLog.id}
				/>
			)}
		</>
	)
}

export default WorkLogDetail
