import ArrowLeft from 'assets/svgs/ArrowLeft'
import ButtonComponent from 'components/elements/Button/Button'
import SelectDriverOptions from 'components/elements/SelectOptions/SelectDriverOptions'
import SelectProviderOptions from 'components/elements/SelectOptions/SelectProviderOptions'
import { RouteTypeName } from 'models/Transfer'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import './DailyFilterModal.css'

export interface Filters {
	selectedRoutes: RouteTypeName[]
	selectedDriver: number | number[] | undefined
	selectedProvider: number | number[] | null
}

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	filters: Filters
	setFilters: Dispatch<SetStateAction<Filters>>
	handleResetFilters: (newFilters: Filters) => void
	handleApplyFilters: (newFilters: Filters) => void
}

const DailyFilterModal: FC<Props> = (props) => {
	const { isOpen, setIsOpen, filters, setFilters, handleResetFilters, handleApplyFilters } = props
	const { t } = useTranslation()
	const { routeTypes, colors } = useConfigStore()
	const [showContent, setShowContent] = useState(false)
	const [tempFilters, setTempFilters] = useState<Filters>(filters)

	useEffect(() => {
		if (isOpen) {
			setShowContent(true)
			setTempFilters(filters)
		}
	}, [isOpen])

	const handleRoutes = (routeType: RouteTypeName) => {
		setTempFilters((prevFilters) => ({
			...prevFilters,
			selectedRoutes: prevFilters.selectedRoutes.includes(routeType)
				? prevFilters.selectedRoutes.filter((route) => route !== routeType)
				: [...prevFilters.selectedRoutes, routeType]
		}))
	}

	const isSelectedRoute = (routeType: RouteTypeName) =>
		tempFilters.selectedRoutes.includes(routeType)

	const isDisabledApply = () =>
		!tempFilters.selectedRoutes.length &&
		tempFilters.selectedDriver === undefined &&
		tempFilters.selectedProvider === null

	const handleReset = () => {
		const initialFilters: Filters = {
			selectedRoutes: [],
			selectedDriver: undefined,
			selectedProvider: null
		}
		setTempFilters(initialFilters)
		handleResetFilters(initialFilters)
	}
	const handleApply = () => {
		handleApplyFilters(tempFilters)
		setFilters(tempFilters)
		handleClose()
	}

	const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			handleClose()
		}
	}

	const handleClose = () => {
		setShowContent(false)
		setTimeout(() => {
			setIsOpen(false)
		}, 300)
	}

	return (
		<div
			className={`filter-modal-container ${showContent ? 'show' : ''}`}
			onClick={handleOutsideClick}>
			<div
				className={`filter-modal-content ${showContent ? 'open' : ''}`}
				onClick={(e) => e.stopPropagation()}>
				<div className='filter-modal-title-container'>
					<ButtonComponent
						className='filter-modal-arrow'
						variant='only-icon'
						onClick={handleClose}
						icon={<ArrowLeft />}
					/>
					<h1>{t('daily.filter_transfers')}</h1>
				</div>

				<div className='filter-modal-filters-container'>
					<div className='filter-by-route'>
						<label>{t('daily.filter_by_route')}</label>
						{routeTypes.allIds.map((id) => {
							const type = routeTypes.byId[id]
							return (
								<div
									key={type.id}
									className={`filter-card ${
										isSelectedRoute(type.name) ? 'filter-card-selected' : ''
									}`}
									style={{
										border: `1px solid ${colors[type.name]}`
									}}
									onClick={() => handleRoutes(type.name)}>
									<div
										className='filter-card-square'
										style={{ backgroundColor: colors[type.name] }}></div>
									<p>{t(`transfer.route.${type.name}`)}</p>
								</div>
							)
						})}
					</div>
					<div className='filter-by-driver'>
						<SelectDriverOptions
							onSelect={(driverId) =>
								setTempFilters((prevFilters) => ({ ...prevFilters, selectedDriver: driverId }))
							}
							selectedId={tempFilters.selectedDriver}
							label={t('daily.filter_by_driver')}
						/>
					</div>
					<div className='filter-by-provider'>
						<SelectProviderOptions
							onSelect={(providerId) =>
								setTempFilters((prevFilters) => ({ ...prevFilters, selectedProvider: providerId }))
							}
							selected={tempFilters.selectedProvider}
							label={t('daily.filter_by_provider')}
						/>
					</div>
				</div>

				<div className='filter-modal-controls-container'>
					<ButtonComponent onClick={handleReset} variant='only-text' className='button-padding'>
						{t('daily.reset')}
					</ButtonComponent>
					<ButtonComponent
						onClick={handleApply}
						className='button-padding'
						disabled={isDisabledApply()}>
						{t('daily.apply_filters')}
					</ButtonComponent>
				</div>
			</div>
		</div>
	)
}

export default DailyFilterModal
