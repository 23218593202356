export enum StatusTabs {
	active = 'active',
	inactive = 'inactive'
}

export const enum RequestTypeTab {
	received = 'received',
	sent = 'sent'
}

export const enum FriendTab {
	friends = 'friends',
	requests = 'requests'
}

export const enum ExportFormat {
	xls = 'xls',
	pdf = 'pdf'
}

export type HistoricalExportType = keyof typeof ExportFormat
export type WorkLogExportType = keyof typeof ExportFormat
