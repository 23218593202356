import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import ButtonComponent from '../Button/Button'
import './PdfViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface Props {
	pdf: string
}

const INITIAL_PAGE_NUMBER = 1
const MIN_SCALE = 1
const MAX_SCALE = 3
const PdfViewer: FC<Props> = ({ pdf }) => {
	const { t } = useTranslation()
	const [pageWidth, setPageWidth] = useState<number>(window.innerWidth)
	const containerRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const updateWidth = () => {
			if (containerRef.current) {
				const containerWidth = containerRef.current.clientWidth
				setPageWidth(containerWidth)
			}
		}

		updateWidth()
		window.addEventListener('resize', updateWidth)

		return () => window.removeEventListener('resize', updateWidth)
	}, [])

	return (
		<TransformWrapper minScale={MIN_SCALE} maxScale={MAX_SCALE} centerOnInit={true}>
			{({ zoomIn, zoomOut }) => (
				<>
					<div ref={containerRef} className='pdf-container'>
						<TransformComponent>
							<Document file={pdf}>
								<Page pageNumber={INITIAL_PAGE_NUMBER} width={pageWidth} />
							</Document>
						</TransformComponent>
					</div>
					<div className='pagination'>
						<ButtonComponent onClick={() => zoomOut()}>-</ButtonComponent>
						<span>{t('general.zoom')}</span>
						<ButtonComponent onClick={() => zoomIn()}>+</ButtonComponent>
					</div>
				</>
			)}
		</TransformWrapper>
	)
}

export default PdfViewer
