import Footer from 'components/elements/Footer/Footer.tsx'
import {
	DAILY_URL,
	HISTORICAL_URL,
	HOME_URL,
	ONBOARDING_URL,
	RESOURCES_URL,
	SETTINGS_URL
} from 'constants/routes.ts'
import React from 'react'
import { useLocation } from 'react-router-dom'
import './DashboardLayout.css'

interface Props {
	children: React.ReactNode
}

const DashboardLayout = (props: Props) => {
	const { children } = props

	const location = useLocation()
	const pathname = location.pathname

	const routesToDisplayFooter = [
		ONBOARDING_URL,
		HOME_URL,
		DAILY_URL,
		RESOURCES_URL,
		SETTINGS_URL,
		HISTORICAL_URL
	]
	const hasToShowFooter = routesToDisplayFooter.some((route) => pathname.includes(route))

	return (
		<div className='container-app'>
			<div className='dashboard-layout'>
				<div className='content'>
					<div className='content-children'>{children}</div>
				</div>
			</div>
			{hasToShowFooter && (
				<div className='footer-container'>
					<Footer />
				</div>
			)}
		</div>
	)
}

export default DashboardLayout
