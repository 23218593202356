import { Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import { emptyString } from 'utils/common'
import Loading from '../Loading/Loading'
import './ThreeDotsModal.css'

interface MenuItem {
	label: string
	href: string
	icon: JSX.Element
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	skip?: boolean
}

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	menuItems: MenuItem[]
	loading?: boolean
}

const ThreeDotsModal = ({ isOpen, setIsOpen, menuItems, loading = false }: Props) => {
	const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			e.stopPropagation()
			setIsOpen(false)
		}
	}

	return (
		<div
			className={`three-dots-modal-container ${isOpen ? 'show' : emptyString}`}
			onClick={handleOutsideClick}>
			<div
				className={`three-dots-modal-content ${isOpen ? 'open' : emptyString}`}
				onClick={(e) => e.stopPropagation()}>
				{loading ? (
					<Loading noBorder hasText={false} />
				) : (
					menuItems
						.filter((item) => !item.skip)
						.map((item, index) => (
							<Link to={item.href} className='menu-item' key={index} onClick={item?.onClick}>
								{item?.icon} {item.label}
							</Link>
						))
				)}
			</div>
		</div>
	)
}

export default ThreeDotsModal
