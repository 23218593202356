import { useMutation, useQueryClient } from '@tanstack/react-query'
import { workLogQueryKey, workLogsQueryKey } from 'constants/reactQueryKeys'
import { User } from 'models/User'
import { WorkLog, WorkLogPost } from 'models/WorkLog'
import { useTranslation } from 'react-i18next'
import {
	createWorkLogService,
	deleteWorkLogByIdService,
	updateWorkLogByIdService
} from 'services/workLog'
import useToast from './useToast'

const useWorkLog = () => {
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const { showSuccessToast } = useToast()

	// CREATE WORKLOG
	const { mutateAsync: createWorkLog } = useMutation({
		mutationFn: createWorkLogService,
		onError: (error: unknown) => {
			console.error('Error creating worklog:', error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [workLogsQueryKey] })
			showSuccessToast({
				title: t('work-log.toast.success-create.title'),
				description: t('work-log.toast.success-create.description')
			})
		}
	})

	// UPDATE WORKLOG
	const { mutateAsync: updateWorkLog } = useMutation({
		mutationFn: ({ id, data }: { id: WorkLog['id']; data: WorkLogPost; userId?: User['id'] }) =>
			updateWorkLogByIdService(id, data),
		onError: (error: unknown) => {
			console.error('Error updating worklog:', error)
		},
		onSuccess: (_result, variables) => {
			const { data, userId } = variables
			queryClient.invalidateQueries({ queryKey: [workLogsQueryKey] })
			queryClient.invalidateQueries({ queryKey: [workLogQueryKey, data.startDay, userId] })
			showSuccessToast({
				title: t('work-log.toast.success-update.title'),
				description: t('work-log.toast.success-update.description')
			})
		}
	})

	// DELETE WORKLOG
	const { mutateAsync: deleteWorkLog } = useMutation({
		mutationFn: ({ id }: { id: WorkLog['id'] }) => deleteWorkLogByIdService(id),
		onError: (error: unknown) => {
			console.error('Error deleting worklog:', error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [workLogsQueryKey] })
			showSuccessToast({
				title: t('work-log.toast.success-delete.title'),
				description: t('work-log.toast.success-delete.description')
			})
		}
	})

	return { createWorkLog, updateWorkLog, deleteWorkLog }
}

export default useWorkLog
