import TransferReviewStatusIcon from 'assets/svgs/TransferReviewStatusIcon'
import { TransferReview } from 'models/Transfer'
import { formatDateTimeToSpanish } from 'utils/dateUtils'

interface Props {
	transferReviews: TransferReview[]
	small?: boolean
}

const TransferReviewedTag: React.FC<Props> = ({ transferReviews, small = false }) => {
	if (transferReviews.length === 0) {
		return null
	}

	return (
		<div className={`transfer-reviews-category-tag ${small ? 'small' : ''}`}>
			<div className='transfer-reviews-category-tag'>
				<TransferReviewStatusIcon width={16} height={16} />
				{!small ? formatDateTimeToSpanish(transferReviews[0].reviewedDate) : null}
			</div>
		</div>
	)
}

export default TransferReviewedTag
