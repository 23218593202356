interface Props {
	width?: number
	height?: number
}

const ClearCrossIcon = ({ width = 22, height = 22 }: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			width={width}
			height={height}>
			<line x1='18' y1='6' x2='6' y2='18'></line>
			<line x1='6' y1='6' x2='18' y2='18'></line>
		</svg>
	)
}

export default ClearCrossIcon
