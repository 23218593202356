interface Props {
	width?: number
	height?: number
}

const RowsIcon = ({ width = 20, height = 21 }: Props) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 20 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.25 11.125H3.75C3.3356 11.125 2.93817 11.2896 2.64515 11.5826C2.35212 11.8757 2.1875 12.2731 2.1875 12.6875V15.5C2.1875 15.9144 2.35212 16.3118 2.64515 16.6049C2.93817 16.8979 3.3356 17.0625 3.75 17.0625H16.25C16.6644 17.0625 17.0618 16.8979 17.3549 16.6049C17.6479 16.3118 17.8125 15.9144 17.8125 15.5V12.6875C17.8125 12.2731 17.6479 11.8757 17.3549 11.5826C17.0618 11.2896 16.6644 11.125 16.25 11.125ZM15.9375 15.1875H4.0625V13H15.9375V15.1875ZM16.25 3.9375H3.75C3.3356 3.9375 2.93817 4.10212 2.64515 4.39515C2.35212 4.68817 2.1875 5.0856 2.1875 5.5V8.3125C2.1875 8.7269 2.35212 9.12433 2.64515 9.41735C2.93817 9.71038 3.3356 9.875 3.75 9.875H16.25C16.6644 9.875 17.0618 9.71038 17.3549 9.41735C17.6479 9.12433 17.8125 8.7269 17.8125 8.3125V5.5C17.8125 5.0856 17.6479 4.68817 17.3549 4.39515C17.0618 4.10212 16.6644 3.9375 16.25 3.9375ZM15.9375 8H4.0625V5.8125H15.9375V8Z'
				fill='#343330'
			/>
		</svg>
	)
}

export default RowsIcon
