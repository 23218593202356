import FilePdfIcon from 'assets/svgs/FilePdfIcon'
import FileXlsIcon from 'assets/svgs/FileXlsIcon'
import GenerateDocumentModal from 'components/elements/ExportModal/GenerateDocumentModal'
import colors from 'constants/colors'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { ExportFormat } from 'models/Elements'
import ActionButton from '../ActionButton/ActionButton'
import { ColumnItem } from '../DragAndDropColumnList/DragAndDropColumnList'
import ModalFormLayout from '../Modal/ModalFormLayout/ModalFormLayout'

interface ExportModalProps {
	isVisible: boolean
	toggleModalVisibility: () => void
	select: (type: ExportFormat) => void
	generateDocModalIsVisible: boolean
	toggleGenerateDocModalVisibility: () => void
	handleExport?: (columns: ColumnItem[]) => Promise<void>
	loading: boolean
	title: string
	xlsButtonText: string
	pdfButtonText: string
	generateButtonText: string
	generateDescriptionText: string
}

const ExportModal = ({
	isVisible,
	toggleModalVisibility,
	select,
	generateDocModalIsVisible,
	toggleGenerateDocModalVisibility,
	handleExport,
	loading,
	title,
	xlsButtonText,
	pdfButtonText,
	generateButtonText,
	generateDescriptionText
}: ExportModalProps) => {
	return (
		<ModalFormLayout title={title} isVisible={isVisible} toggleVisibility={toggleModalVisibility}>
			<FormSectionLayout smallGap>
				<ActionButton
					icon={<FileXlsIcon />}
					backGroundIcon={colors.xlsGreen}
					onClick={() => select(ExportFormat.xls)}>
					{xlsButtonText}
				</ActionButton>
				<ActionButton
					icon={<FilePdfIcon />}
					backGroundIcon={colors.pdfRed}
					onClick={() => select(ExportFormat.pdf)}>
					{pdfButtonText}
				</ActionButton>
			</FormSectionLayout>
			{handleExport ? (
				<GenerateDocumentModal
					isVisible={generateDocModalIsVisible}
					toggleModalVisibility={toggleGenerateDocModalVisibility}
					exportData={handleExport}
					loading={loading}
					generateButtonText={generateButtonText}
					generateDescriptionText={generateDescriptionText}
				/>
			) : null}
		</ModalFormLayout>
	)
}

export default ExportModal
