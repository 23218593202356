import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'

interface DownloadFileOptions {
	fileBase64: string
	fileName: string
	mimeType: string
	showErrorToast: (error: { description: string }) => void
}

const downloadAndOpenFile = async ({
	fileBase64,
	fileName,
	mimeType,
	showErrorToast
}: DownloadFileOptions): Promise<void> => {
	try {
		const isNative = Capacitor.isNativePlatform()

		if (isNative) {
			const filePath = `${fileName}`

			await Filesystem.writeFile({
				path: filePath,
				data: fileBase64,
				directory: Directory.Documents
			})

			const result = await Filesystem.getUri({
				directory: Directory.Documents,
				path: filePath
			})

			await Share.share({
				url: result.uri,
				title: `Descargar ${fileName}`,
				text: `Aquí está tu archivo exportado.`
			})
		} else {
			const blob = new Blob([Uint8Array.from(atob(fileBase64), (c) => c.charCodeAt(0))], {
				type: mimeType
			})
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	} catch (error) {
		console.error(`Error downloading ${fileName}: `, error)
		showErrorToast({ description: 'Failed to download the file.' })
	}
}

export default downloadAndOpenFile
