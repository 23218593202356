import React from 'react'

interface Props {
	width?: number
	height?: number
}

const DownloadIcon: React.FC<Props> = (props) => {
	const { width = 24, height = 24 } = props

	return (
		<svg
			fill='#000000'
			id='Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			xmlSpace='preserve'
			{...props}>
			<g>
				<g>
					<g>
						<path d='M5,9C4.4,9,4,8.6,4,8c0-3.9,3.1-7,7-7c3,0,5.7,2,6.7,4.9C17.8,6.4,17.6,7,17,7.1c-0.5,0.2-1.1-0.1-1.3-0.6 C15.1,4.4,13.2,3,11,3C8.2,3,6,5.2,6,8C6,8.6,5.6,9,5,9z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M18,17c-0.6,0-1-0.4-1-1s0.4-1,1-1c2.2,0,4-1.8,4-4s-1.8-4-4-4c-0.3,0-0.7,0-1,0.1c-0.5,0.1-1.1-0.2-1.2-0.7 c-0.1-0.5,0.2-1.1,0.7-1.2C17,5.1,17.5,5,18,5c3.3,0,6,2.7,6,6S21.3,17,18,17z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M8,17H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S8.6,17,8,17z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M18,17h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S18.6,17,18,17z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M5,17c-2.8,0-5-2.2-5-5s2.2-5,5-5c0.6,0,1,0.4,1,1S5.6,9,5,9c-1.7,0-3,1.3-3,3s1.3,3,3,3c0.6,0,1,0.4,1,1S5.6,17,5,17z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M12,23c-0.6,0-1-0.4-1-1v-9c0-0.6,0.4-1,1-1s1,0.4,1,1v9C13,22.6,12.6,23,12,23z' />
					</g>
				</g>
				<g>
					<g>
						<path d='M12,23c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3-3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3,3C12.5,22.9,12.3,23,12,23z ' />
					</g>
				</g>
				<g>
					<g>
						<path d='M12,23c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3,3c0.4,0.4,0.4,1,0,1.4C12.5,22.9,12.3,23,12,23z ' />
					</g>
				</g>
			</g>
		</svg>
	)
}

export default DownloadIcon
