import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { LOGOUT_URL, MY_PROFILE_URL } from 'constants/routes'
import { FormikProps, useFormik } from 'formik'
import { useModal } from 'hooks/useModal'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { ChangeMyPasswordForm, UserForm } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useUserStore } from 'stores/useUserStore'
import { emptyString, minPasswordLength } from 'utils/common'
import { getFormikError } from 'utils/formikUtils'
import * as yup from 'yup'
import './MyProfile.css'

const MyProfileEdit: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const userState = useUserStore()
	const currentUser = userState.currentUser
	const { changeMyPassword, updateUser, removeUser } = useUsers()
	const [loading, setLoading] = useState(false)
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()

	const formik = useFormik<Partial<UserForm>>({
		initialValues: {
			name: emptyString,
			lastName: emptyString
		},
		validationSchema: yup.object().shape({
			name: yup
				.string()
				.required(t('errors.required_m', { field: t('settings.my_account.profile.name_label') })),
			lastName: yup
				.string()
				.required(t('errors.required_m', { field: t('settings.my_account.profile.surname_label') }))
		}),
		onSubmit: async (values: Partial<UserForm>) => {
			try {
				setLoading(true)
				await updateUser({
					user: values,
					userId: currentUser.id
				})
				toast.success(t('toast.success.description'))
				navigate(MY_PROFILE_URL)
			} catch (error) {
				console.error({ error })
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})

	const changePasswordForm = useFormik<ChangeMyPasswordForm>({
		initialValues: {
			newPassword: emptyString
		},
		validationSchema: yup.object().shape({
			newPassword: yup
				.string()
				.min(minPasswordLength, t('errors.min_characters', { num: minPasswordLength }))
				.required(t('errors.min_characters', { num: minPasswordLength }))
		}),
		onSubmit: async (values: ChangeMyPasswordForm) => {
			try {
				setLoading(true)
				await changeMyPassword(values)
				toast.success(t('toast.success.description'))
				changePasswordForm.resetForm()
			} catch (error) {
				console.error({ error })
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})

	const deleteAccount = async () => {
		try {
			await removeUser(currentUser.id)
			navigate(LOGOUT_URL)
		} catch (error) {
			console.error({ error })
			toast.error(t('toast.error.description'))
		}
	}

	useEffect(() => {
		formik.setValues({
			name: currentUser.name,
			lastName: currentUser.lastName
		})
	}, [currentUser])

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.my_account.profile.title')}
			leftButton={<ButtonBack url={MY_PROFILE_URL} />}
			rightButton={
				<ButtonComponent onClick={formik.handleSubmit} variant='only-text'>
					<strong>{t('general.save')}</strong>
				</ButtonComponent>
			}>
			<FormSectionLayout
				title={t('settings.my_account.profile.subtitle')}
				className='my-profile-edit'>
				<Input
					id='name'
					label={t('settings.my_account.profile.name_label')}
					placeholder={t('settings.my_account.profile.name_label')}
					valueSelected={formik.values.name}
					onChange={(e) => formik.setFieldValue('name', e.target.value)}
					error={getFormikError(formik as FormikProps<UserForm>, 'name')}
				/>
				<Input
					id='lastName'
					label={t('settings.my_account.profile.surname_label')}
					placeholder={t('settings.my_account.profile.surname_label')}
					valueSelected={formik.values.lastName}
					onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
					error={getFormikError(formik as FormikProps<UserForm>, 'lastName')}
				/>
				<Input
					id='newPassword'
					valueSelected={changePasswordForm.values.newPassword}
					onChange={(e) => changePasswordForm.setFieldValue('newPassword', e.target.value)}
					label={t('settings.my_account.profile.password_label')}
					placeholder='********'
					error={getFormikError(
						changePasswordForm as FormikProps<ChangeMyPasswordForm>,
						'newPassword'
					)}
					icon={
						<ButtonComponent
							className='my-profile-change-password'
							variant='only-text'
							onClick={changePasswordForm.handleSubmit}>
							{t('settings.my_account.profile.change_password')}
						</ButtonComponent>
					}
				/>
			</FormSectionLayout>
			<div className='button-container'>
				<ButtonComponent className='delete-account' onClick={toggleDeleteModal}>
					{t('iam.delete_account')}
				</ButtonComponent>
			</div>

			<ModalActions
				type='error'
				title={t('iam.delete_account_modal_title')}
				description={t('iam.delete_account_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={(e?: React.MouseEvent) => {
					e?.stopPropagation()
					toggleDeleteModal()
				}}
				primaryButton={{
					text: t('general.remove'),
					onClick: async (e?: React.MouseEvent) => {
						e?.stopPropagation()
						deleteAccount()
					}
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: (e?: React.MouseEvent) => {
						e?.stopPropagation()
						toggleDeleteModal()
					}
				}}
			/>
		</FormLayout>
	)
}

export default MyProfileEdit
