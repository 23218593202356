const colors = {
	primary: '#734CEA',
	hasStartBorder: '#D2E2F0',
	hasStartBackground: '#F9FCFF',
	hasEndBorder: '#FEDD25',
	hasEndBackground: '#FEDD2580',
	warningDark: '#ff8e3c',
	successDark: '#34ae89',
	errorDark: '#e83642',
	violetMt: '#713fff',
	shinyViolet: '#713FFF26',
	blueMt: '#130d2a',
	stepNumberText: '#130D2A80',
	lightBlueMt: '#f0f2ff',
	shinyBlue: '#3F52FF1A',
	backgroundInput: '#f7f5fe',
	backgroundInputFocused: '#F3EEFF',
	borderInput: '#EBE5FC',
	neutral00: '#ffffff',
	actionIconWhatsappColor: '#9AE5A0',
	whatsappGreen: '#60D669',
	xlsGreen: '#217346',
	pdfRed: '#F45151',
	error: '#F3535D',
	cancelled: '#f4516c',
	cancelledLight: '#f4516c38',
	bodyMt: '#2d2d47',
	green: '#009951',
	black: '#000000'
}

export default colors
