import AirplaneLanding from 'assets/svgs/AirplaneLanding'
import AirplaneTakeoff from 'assets/svgs/AirplaneTakeoff'
import BarricadeIcon from 'assets/svgs/BarricadeIcon'
import CarIcon from 'assets/svgs/CarIcon'
import CarProfileIcon from 'assets/svgs/CarProfileIcon'
import InputSwitch from 'components/elements/Input/InputSwitch'
import SimpleColouredCard from 'components/elements/SimpleColouredCard/SimpleColouredCard'
import { CREATE_URL, CREATE_URL_NO_STEPS, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { TOAST_DURATION } from 'constants/toast'
import useToast from 'hooks/useToast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { RouteTypeName, TranslationKeys } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { useStepModeStore } from 'stores/useStepModeStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import './StepRoute.css'

const StepRoute = () => {
	const { withStepsMode, setWithStepsMode } = useStepModeStore()
	const { showSuccessToast } = useToast()
	const { routeTypes, colors, secondaryColors } = useConfigStore()

	const { transferId } = useParams()

	const navigate = useNavigate()
	const { t } = useTranslation()
	const updateActualTransferCreation = useTransfersStore(
		(state) => state.updateActualTransferCreation
	)

	const ROUTES = [
		{
			name: 'transfer.route.departure',
			type: RouteTypeName.departure,
			icon: <AirplaneTakeoff />,
			color: colors.departure,
			secondaryColor: secondaryColors.departure
		},
		{
			name: 'transfer.route.arrival',
			type: RouteTypeName.arrival,
			icon: <AirplaneLanding />,
			color: colors.arrival,
			secondaryColor: secondaryColors.arrival
		},
		{
			name: 'transfer.route.transfer',
			type: RouteTypeName.transfer,
			icon: <CarIcon isActive activeColor={colors.transfer} />,
			color: colors.transfer,
			secondaryColor: secondaryColors.transfer
		},
		{
			name: 'transfer.route.disposition',
			type: RouteTypeName.disposition,
			icon: <CarProfileIcon />,
			color: colors.disposition,
			secondaryColor: secondaryColors.disposition
		},
		{
			name: 'transfer.route.crane',
			type: RouteTypeName.crane,
			icon: <BarricadeIcon />,
			color: colors.crane,
			secondaryColor: secondaryColors.crane
		}
	] as {
		name: TranslationKeys
		type: keyof typeof RouteTypeName
		icon: JSX.Element
		color: string
		secondaryColor: string
	}[]

	const hasStepsOnBookings = (withSteps: boolean, routeType: keyof typeof RouteTypeName) => {
		const url = withSteps
			? `${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.DETAILS.toLocaleLowerCase()}`
			: `${TRANSFER_URL}${CREATE_URL_NO_STEPS}/${routeType}`
		return url
	}

	const handleRoute = (routeType: keyof typeof RouteTypeName) => {
		updateActualTransferCreation({ route: routeType })
		let targetUrl = hasStepsOnBookings(withStepsMode, routeType)
		if (transferId) {
			targetUrl = `${targetUrl}/${transferId}`
		}
		navigate(targetUrl)
	}

	const handleChange = () => {
		setWithStepsMode(!withStepsMode)
		showSuccessToast({
			title: t('transfer.create_steps.toast.title'),
			description: !withStepsMode
				? t('transfer.create_steps.toast.description')
				: t('transfer.create_steps.toast.description_2'),
			duration: TOAST_DURATION
		})
	}

	const validRoutes = ROUTES.filter((route) =>
		routeTypes.allIds.some((id) => routeTypes.byId[id]?.name === route.type)
	)

	return (
		<FormTransferLayout step={1}>
			<FormSectionLayout title={t('transfer.create_steps.step_route.h1')} noDivider>
				<div className='step-route-description-switch-container'>
					<span className='step-route-description'>{t('transfer.create_steps.step_route.h2')}</span>
					<InputSwitch
						label={t('transfer.create_steps.switch_label')}
						id='switch-steps'
						value={withStepsMode}
						onChange={handleChange}
					/>
				</div>
				<div className='step-route-card-container'>
					{validRoutes.map((route) => (
						<SimpleColouredCard
							key={route.name}
							icon={route.icon}
							title={t(route.name)}
							mainColor={route.color}
							secondaryColor={route.secondaryColor}
							onClick={() => {
								handleRoute(route.type)
							}}
						/>
					))}
				</div>
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepRoute
