import PlusIcon from 'assets/svgs/PlusIcon'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import colors from 'constants/colors'
import { DAILY_URL, WORK_LOG_DRIVERS_HISTORY_URL, WORK_LOG_HISTORY_URL, WORK_LOG_REGISTER_URL } from 'constants/routes'
import useWorkLogs from 'hooks/useWorkLogs'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { currentUserIsAdmin } from 'utils/currentUser'
import { formatDateToSpanish } from 'utils/dateUtils'
import './WorkLog.css'
import WorkLogTable from './WorkLogTable/WorkLogTable'

const WorkLog = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { workLogs } = useWorkLogs()

	const isAdmin = currentUserIsAdmin()

	return (
		<FormLayout title={t('work-log.title')} leftButton={<ButtonBack url={DAILY_URL} />}>
			<div className='work-log-container'>
				<div className='work-log-section'>
					<span>{formatDateToSpanish()}</span>
					<ActionButton
						icon={<PlusIcon />}
						backGroundIcon={colors.lightBlueMt}
						onClick={() => navigate(WORK_LOG_REGISTER_URL)}>
						{t('work-log.work-log-register')}
					</ActionButton>
				</div>
				{!workLogs.length ||
					(isAdmin ? (
						<div className='work-log-section last-work-logs-section'>
							<div className='last-work-logs-header'>
								<h3>{t('work-log.last-work-logs')}</h3>
								<Link to={WORK_LOG_HISTORY_URL}>{t('work-log.see-history')}</Link>
							</div>
							<WorkLogTable noPaginate perPage={5} />
							<div className='last-work-logs-header'>
								<h3>{t('work-log.drivers')}</h3>
								<Link to={WORK_LOG_DRIVERS_HISTORY_URL}>{t('work-log.see-history')}</Link>
							</div>
							<WorkLogTable noPaginate perPage={5} showOtherUsers />
						</div>
					) : (
						<div className='work-log-section last-work-logs-section'>
							<div className='last-work-logs-header'>
								<h3>{t('work-log.last-work-logs')}</h3>
								<Link to={WORK_LOG_HISTORY_URL}>{t('work-log.see-history')}</Link>
							</div>
							<WorkLogTable noPaginate />
						</div>
					))}
			</div>
		</FormLayout>
	)
}

export default WorkLog
