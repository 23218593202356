import { useQuery } from '@tanstack/react-query'
import { UserRoles } from 'models/User'
import { getTransfersReviewsByDateService } from 'services/transferReview'

import { useUserStore } from 'stores/useUserStore'

export const useTransfersReview = (date: string) => {
	const userState = useUserStore()
	const currentUser = userState.currentUser
	const isAdmin =
		currentUser.userTypeId === UserRoles.admin ||
		currentUser.userTypeId === UserRoles['super-admin']

	//ADMIN getTransferReviewsByBookingIdService
	const {
		data: adminTransferReviews,
		isLoading: isLoadingAdminTransferReviews,
		error: adminTransferReviewsError,
		isError: isErrorAdminTransferReviews
	} = useQuery({
		queryKey: ['adminTransferReviews', date],
		queryFn: () => getTransfersReviewsByDateService(date),
		enabled: isAdmin,
		refetchOnWindowFocus: true
	})

	return {
		adminTransferReviews: adminTransferReviews ? adminTransferReviews : [],
		isLoadingAdminTransferReviews,
		isErrorAdminTransferReviews,
		adminTransferReviewsError
	}
}
