interface Props extends React.SVGProps<SVGSVGElement> {
	width?: number | string
	height?: number | string
}

const SMSIcon: React.FC<Props> = ({ width = 20, height = 24 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{
				transform: 'translate(-50%, -50%) scale(1.03) translate(50%, 50%)'
			}}>
			<path
				d='M9.99993 0.25C8.31662 0.249633 6.66188 0.685083 5.19679 1.51396C3.7317 2.34285 2.50616 3.53692 1.63947 4.97997C0.772772 6.42301 0.294445 8.06587 0.251045 9.74862C0.207644 11.4314 0.600649 13.0967 1.39181 14.5825L0.327743 17.7747C0.239604 18.039 0.226813 18.3226 0.290804 18.5938C0.354795 18.8649 0.493039 19.1129 0.690042 19.3099C0.887044 19.5069 1.13502 19.6451 1.40618 19.7091C1.67733 19.7731 1.96095 19.7603 2.22524 19.6722L5.41743 18.6081C6.72503 19.3036 8.17388 19.6921 9.65402 19.744C11.1342 19.7959 12.6067 19.51 13.9598 18.9079C15.3129 18.3057 16.5111 17.4033 17.4634 16.269C18.4156 15.1346 19.097 13.7983 19.4557 12.3613C19.8144 10.9244 19.841 9.42456 19.5335 7.97578C19.226 6.527 18.5925 5.16731 17.6811 3.99992C16.7697 2.83253 15.6043 1.88811 14.2733 1.23836C12.9424 0.588614 11.481 0.250608 9.99993 0.25ZM9.99993 18.25C8.5496 18.251 7.12471 17.8691 5.86931 17.1428C5.77739 17.0895 5.6752 17.0563 5.56949 17.0455C5.46379 17.0347 5.357 17.0464 5.25618 17.08L1.74993 18.25L2.91899 14.7438C2.95269 14.643 2.9646 14.5362 2.95394 14.4305C2.94327 14.3248 2.91027 14.2226 2.85712 14.1306C1.94772 12.5584 1.5826 10.7299 1.8184 8.92896C2.0542 7.12801 2.87774 5.4552 4.16126 4.17006C5.44479 2.88491 7.11655 2.05925 8.91721 1.82118C10.7179 1.5831 12.5468 1.94591 14.1202 2.85333C15.6936 3.76074 16.9236 5.16204 17.6193 6.83983C18.3151 8.51762 18.4377 10.3781 17.9681 12.1327C17.4986 13.8873 16.4632 15.4379 15.0224 16.5439C13.5817 17.65 11.8163 18.2497 9.99993 18.25Z'
				fill='#343330'
			/>
		</svg>
	)
}

export default SMSIcon
