import { RoadHorizon } from 'assets/svgs'
import { FC } from 'react'
import './NoTransfers.css'

interface Props {
	messageKey: string
	marginTop?: boolean
}

const NoTransfers: FC<Props> = (props) => {
	const { messageKey, marginTop = false } = props

	return (
		<div className={`no-transfers-day ${marginTop ? 'margin-top' : ''}`}>
			<div className='icon-road'>
				<RoadHorizon />
			</div>
			<p>{messageKey}</p>
		</div>
	)
}

export default NoTransfers
