import { WorkLog, WorkLogFilters, WorkLogItem } from 'models/WorkLog'
import { useTranslation } from 'react-i18next'
import { getWorkLogService } from 'services/workLog'
import { emptyString } from 'utils/common'

const useWorkLogsUtils = () => {
	const { t } = useTranslation()

	const formatTimeWithoutSeconds = (time: string): string => {
		if (!time) return emptyString
		return time.substring(0, 5)
	}

	const formatMinutesToHoursAndMinutes = (totalMinutes: number): string => {
		const hours = Math.floor(totalMinutes / 60)
		const minutes = totalMinutes % 60
		return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`
	}

	const timeToMinutes = (time: string): number => {
		if (!time || time === emptyString) return 0
		const [hours, minutes] = time.split(':').map(Number)
		return hours * 60 + minutes
	}

	const calculateTimeDifferenceInMinutes = (start: string, end: string): number => {
		if (!start || !end || start === emptyString || end === emptyString) return 0
		const startMinutes = timeToMinutes(start)
		const endMinutes = timeToMinutes(end)
		const diffMinutes = endMinutes - startMinutes
		return diffMinutes > 0 ? diffMinutes : 0
	}

	const calculateTimeDifference = (start: string, end: string): string => {
		const diffMinutes = calculateTimeDifferenceInMinutes(start, end)
		return formatMinutesToHoursAndMinutes(diffMinutes)
	}

	const calculateTotalMinutes = (timeRanges: { start: string; end: string }[]): number => {
		return timeRanges.reduce((total, { start, end }) => {
			return total + calculateTimeDifferenceInMinutes(start, end)
		}, 0)
	}

	const mapWorkLogsToWorkLogItems = (workLogs: WorkLog[]): WorkLogItem[] => {
		const daysOfWeek = [
			t('week_days.sunday'),
			t('week_days.monday'),
			t('week_days.tuesday'),
			t('week_days.wednesday'),
			t('week_days.thursday'),
			t('week_days.friday'),
			t('week_days.saturday')
		]
		const groupedLogs = workLogs.reduce((acc, log) => {
			const date = new Date(log.startDay)
			const dayName = daysOfWeek[date.getDay()]
			const formattedDate = date.toLocaleDateString('es-ES', { day: 'numeric', month: 'short' })
			const key = `${log.startDay}-${log.userId}`
			if (!acc[key]) {
				acc[key] = {
					id: log.id,
					userId: log.userId,
					userName: log.userName,
					userLastName: log.userLastName,
					day: dayName,
					formattedDate: formattedDate,
					time: [],
					observations: log.observations || emptyString,
					date: log.startDay
				}
			}
			const startTime = formatTimeWithoutSeconds(log.startTime)
			const endTime = log.endTime ? `-- ${formatTimeWithoutSeconds(log.endTime)}` : '-- '
			acc[key].time.push(`${startTime} ${endTime}`)
			return acc
		}, {} as Record<string, WorkLogItem>)
		return Object.values(groupedLogs)
	}

	const getWorkLogsByMonth = async (month: Date, userId: number) => {
		const year = month.getFullYear()
		const monthNum = month.getMonth() + 1
		const firstDay = `${year}-${String(monthNum).padStart(2, '0')}-01`
		const lastDay = new Date(year, monthNum, 0)
		const lastDayFormatted = `${year}-${String(monthNum).padStart(2, '0')}-${String(
			lastDay.getDate()
		).padStart(2, '0')}`
		const monthFilters: Partial<WorkLogFilters> = {
			startDate: firstDay,
			endDate: lastDayFormatted,
			employeeId: userId
		}
		const result = await getWorkLogService(1, 100, monthFilters)
		return result.data || []
	}

	const getUniqueWorkLogDays = (workLogs: WorkLog[]): string[] => {
		return [...new Set(workLogs.map((log) => log.startDay))]
	}
	return {
		mapWorkLogsToWorkLogItems,
		calculateTimeDifference,
		formatTimeWithoutSeconds,
		formatMinutesToHoursAndMinutes,
		getWorkLogsByMonth,
		getUniqueWorkLogDays,
		calculateTimeDifferenceInMinutes,
		calculateTotalMinutes
	}
}

export default useWorkLogsUtils
