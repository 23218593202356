import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { REGISTER_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import './DeleteAccountInfo.css'

const DeleteAccountInfo: React.FC = () => {
	return (
		<FormLayout
			title='¿Cómo puedo eliminar mi cuenta de usuario?'
			leftButton={<ButtonBack url={REGISTER_URL} />}>
			<div className='legal-body'>
				<h3>¿Cómo puedo eliminar mi cuenta?</h3>
				<p>
					Para eliminar tu cuenta, sigue estos pasos. Primero, inicia sesión con tu usuario y
					contraseña.
				</p>
				<p>
					Una vez dentro, accede a tu perfil y, en la esquina superior derecha, encontrarás el botón
					del menú lateral. Selecciona la opción "Configuración".
				</p>
				<p>
					Si eres administrador, verás el apartado "Mi cuenta". Haz clic en él y luego en "Mi
					perfil". Si eres usuario, verás directamente el apartado "Mi perfil"; haz clic en él.
				</p>
				<p>
					Dentro de tu perfil, en la parte superior derecha, verás la opción "Editar". Haz clic en
					ella.
				</p>
				<p>
					Desplázate hasta la parte inferior de la página y encontrarás un botón que dice "Eliminar
					cuenta". Haz clic en él.
				</p>
				<p>
					<strong>Importante:</strong> Al eliminar tu cuenta, perderás toda la información que hayas
					introducido en la aplicación. Esta acción es irreversible.
				</p>
			</div>
		</FormLayout>
	)
}

export default DeleteAccountInfo
