import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dailyTransfersQueryKey, transferQueryKey } from 'constants/reactQueryKeys'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteTransferService } from 'services/transfers'

export const useDeleteTransfer = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	const { mutateAsync: deleteTransfer } = useMutation({
		mutationFn: async (id: number) => {
			await deleteTransferService(id)
			return id
		},
		onSuccess: (id) => {
			queryClient.invalidateQueries({
				queryKey: [transferQueryKey, id]
			})
			queryClient.invalidateQueries({
				queryKey: [dailyTransfersQueryKey]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.delete_transfer_error'))
		}
	})

	return {
		deleteTransfer,
		errorMessage
	}
}
