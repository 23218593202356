import { FormikProps } from 'formik'
import { AllStepForms, AllStepFormsWithFomentoForm, StepAddressesForm } from 'models/Transfer'
import { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { emptyString } from 'utils/common'
import Input from './Input'
import './Input.css'

interface Option {
	label: string
	value: string
}

type FieldName = keyof StepAddressesForm | keyof AllStepForms | keyof AllStepFormsWithFomentoForm

interface Props {
	options: Option[]
	placeholder: string
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
	fieldName: FieldName
	error: string
	id: string
	label: string
}

const isStepAddressesForm = (
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
): formik is FormikProps<StepAddressesForm> => {
	return 'routeTypeId' in formik.values
}

const InputAutocomplete = forwardRef<HTMLElement, Props>((props, ref) => {
	const { options, placeholder, formik, fieldName, error, id, label } = props
	const [showOptions, setShowOptions] = useState(false)
	const [filteredOptions, setFilteredOptions] = useState<Option[]>([])
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const [temporaryValue, setTemporaryValue] = useState<string>(emptyString)
	const wrapperRef = useRef<HTMLDivElement>(null)
	const dropdownRef = useRef<HTMLDivElement>(null)

	let value
	if (isStepAddressesForm(formik)) {
		value = formik.values[fieldName as keyof StepAddressesForm]
	} else {
		value = formik.values[fieldName as keyof (AllStepForms | AllStepFormsWithFomentoForm)]
	}

	useEffect(() => {
		if (value) {
			setFilteredOptions(
				options.filter((opt) => opt.value.toLowerCase().includes(value.toString().toLowerCase()))
			)
		} else {
			setFilteredOptions(options)
		}
	}, [value, options])

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!showOptions) return
		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault()
				const nextIndex =
					selectedIndex < filteredOptions.length - 1 ? selectedIndex + 1 : selectedIndex
				setSelectedIndex(nextIndex)
				if (nextIndex >= 0) {
					setTemporaryValue(filteredOptions[nextIndex].value)
				}
				break
			case 'ArrowUp':
				e.preventDefault()
				const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex
				setSelectedIndex(prevIndex)
				if (prevIndex >= 0) {
					setTemporaryValue(filteredOptions[prevIndex].value)
				}
				break
			case 'Enter':
				e.preventDefault()
				if (filteredOptions.length === 1) {
					handleOptionClick(filteredOptions[0].value)
				} else if (selectedIndex >= 0) {
					handleOptionClick(filteredOptions[selectedIndex].value)
				}
				break
			case 'Escape':
				setShowOptions(false)
				setSelectedIndex(-1)
				setTemporaryValue(emptyString)
				break
		}
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value
		formik.setFieldValue(fieldName, newValue)
		setShowOptions(true)
		setSelectedIndex(-1)
		setTemporaryValue(emptyString)
		const filtered = options.filter(
			(opt) =>
				opt.value.toLowerCase().includes(newValue.toLowerCase()) ||
				opt.label.toLowerCase().includes(newValue.toLowerCase())
		)
		setFilteredOptions(filtered)
	}

	const handleOptionClick = (selectedValue: string) => {
		formik.setFieldValue(fieldName, selectedValue)
		setShowOptions(false)
		setSelectedIndex(-1)
		setTemporaryValue(emptyString)
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				setShowOptions(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (selectedIndex >= 0 && dropdownRef.current) {
			const dropdown = dropdownRef.current
			const selectedElement = dropdown.children[selectedIndex] as HTMLElement

			if (selectedElement) {
				const dropdownRect = dropdown.getBoundingClientRect()
				const selectedRect = selectedElement.getBoundingClientRect()

				if (selectedRect.bottom > dropdownRect.bottom) {
					dropdown.scrollTop += selectedRect.bottom - dropdownRect.bottom
				} else if (selectedRect.top < dropdownRect.top) {
					dropdown.scrollTop += selectedRect.top - dropdownRect.top
				}
			}
		}
	}, [selectedIndex])

	useEffect(() => {
		if (!showOptions) {
			setTemporaryValue(emptyString)
		}
	}, [showOptions])

	return (
		<div className='input-wrapper' style={{ position: 'relative' }} ref={wrapperRef}>
			<Input
				type='text'
				id={id}
				label={label}
				placeholder={placeholder}
				valueSelected={showOptions && temporaryValue ? temporaryValue : value?.toString()}
				onChange={handleInputChange}
				onFocus={() => setShowOptions(true)}
				error={error}
				ref={ref as RefObject<HTMLInputElement>}
				onKeyDown={handleKeyDown}
				onClear={() => formik.setFieldValue(fieldName, emptyString)}
			/>

			{showOptions && filteredOptions.length > 0 && (
				<div className='options-dropdown' ref={dropdownRef}>
					{filteredOptions.map((option, index) => (
						<div
							key={option.value}
							onClick={() => handleOptionClick(option.value)}
							className={index === selectedIndex ? 'highlighted' : emptyString}>
							{option.label}
						</div>
					))}
				</div>
			)}
		</div>
	)
})

export default InputAutocomplete
