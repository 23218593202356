import {
	Configuration,
	ConfigurationPaymentMethodsResponse,
	ConfigurationTransferResponse,
	DocumentType,
	DocumentTypesClass,
	FetchResponse,
	Municipality,
	Province,
	Role,
	RouteType,
	UserRoles,
	VehicleClass,
	VehicleClassType
} from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export interface TransferColors {
	departure: string
	arrival: string
	transfer: string
	disposition: string
	crane: string
}
export interface ConfigurationColors {
	colors: TransferColors
	secondaryColors: TransferColors
}

export const getConfigService = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<ConfigurationColors>>(
			`${endpoints.configurations}/colors`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getVehicleClasses = async (): Promise<VehicleClassType[]> => {
	return Promise.resolve([
		{ id: 1, name: VehicleClass.VTC },
		{ id: 2, name: VehicleClass.Taxi },
		{ id: 3, name: VehicleClass.Limusina },
		{ id: 4, name: VehicleClass.Minibus },
		{ id: 5, name: VehicleClass.Autobus }
	])
}

export const getDocumentTypes = async (): Promise<DocumentTypesClass[]> => {
	return Promise.resolve([
		{ id: 1, name: DocumentType.DNI },
		{ id: 2, name: DocumentType.NIE },
		{ id: 4, name: DocumentType.NIF },
		{ id: 3, name: DocumentType.CIF }
	])
}

export const getAllProvinces = async (): Promise<Province[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Province[]>>(
			`${endpoints.settings}/provinces`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllMunicipalities = async (): Promise<Municipality[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Municipality[]>>(
			`${endpoints.settings}/municipalities`,
			{ token }
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getRolesService = async (): Promise<Role[]> => {
	return Promise.resolve([
		{
			id: 1,
			name: 'Super Admin',
			type: UserRoles['super-admin'],
			description: 'Es el rol que tiene poder de todo el sistema.',
			showInUserList: true
		},
		{
			id: 2,
			name: 'Admin',
			type: UserRoles.admin,
			description: 'Es el rol que tiene poder de todo el sistema menos de los usuarios.',
			showInUserList: true
		},
		{
			id: 3,
			name: 'Revisor',
			type: UserRoles.revisor,
			description: 'Es el rol que tiene poder de revisar todo el sistema.',
			showInUserList: false
		},
		{
			id: 4,
			name: 'Proveedor',
			type: UserRoles.provider,
			description: 'Es el rol que tiene poder de proveedor.',
			showInUserList: false
		},
		{
			id: 5,
			name: 'Conductor',
			type: UserRoles.driver,
			description: 'Es el rol que tiene poder de Conductor.',
			showInUserList: false
		}
	])
}

export const getRouteTypesService = async (): Promise<RouteType[]> => {
	try {
		const response = await customFetch<FetchResponse<RouteType[]>>(`${endpoints.settings}/routes`, {
			token: getUserToken()
		})

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getConfigurations = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Configuration[]>>(
			`${endpoints.configurations}`,
			{ token }
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getConfigurationsTransfer = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<ConfigurationTransferResponse>>(
			`${endpoints.configurationsTransfer}`,
			{ token }
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getPaymentMethods = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<ConfigurationPaymentMethodsResponse[]>>(
			`${endpoints.paymentMethods}`,
			{ token }
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}
