import { ENVIRONMENT } from 'constants/environment'
import { TransferFilter, TransferView, type TransferPost } from 'models/Transfer'
import { normalize } from 'normalizr'
import { transferSchema } from 'schemas/index'
import { fetchHistoricalTransfersService } from 'services/transfers'
import { zeroAmount } from 'utils/common'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface State {
	actualTransferCreation: Partial<TransferPost>
	historical: {
		byId: { [id: number]: TransferView }
		allIds: number[]
		totalPrice: number
		totalCommission: number
		totalToll: number
		totalTollWithVat: number
		hidePrice: boolean
	}
	fetchHistoricalTransfers: (filter: TransferFilter) => Promise<TransferView[]>
	resetActualTransferCreation: () => void
	resetAllTransferInfo: () => void
	updateActualTransferCreation: (transfer: Partial<TransferPost>) => void
}

export const useTransfersStore = create<State>()(
	devtools(
		(set) => ({
			actualTransferCreation: {} as Partial<TransferPost>,
			historical: {
				byId: {},
				allIds: [] as number[],
				totalPrice: zeroAmount,
				totalCommission: zeroAmount,
				totalToll: zeroAmount,
				totalTollWithVat: zeroAmount,
				hidePrice: false as boolean
			},
			fetchHistoricalTransfers: async (filter) => {
				const { transfers, totalCommission, totalPrice, totalToll, totalTollWithVat, hidePrice } =
					await fetchHistoricalTransfersService(filter)
				const normalizedData = normalize(transfers, [transferSchema])
				set({
					historical: {
						byId: { ...normalizedData.entities.transfers },
						allIds: normalizedData.result,
						totalCommission,
						totalPrice,
						totalToll,
						totalTollWithVat,
						hidePrice
					}
				})
				return transfers
			},
			resetActualTransferCreation: () => {
				set(
					{
						actualTransferCreation: {}
					},
					false,
					'RESET_ACTUAL_TRANSFER_CREATION'
				)
			},
			resetAllTransferInfo: () => {
				set(
					{
						actualTransferCreation: {},
						historical: {
							byId: {},
							allIds: [],
							totalPrice: zeroAmount,
							totalCommission: zeroAmount,
							totalToll: zeroAmount,
							totalTollWithVat: zeroAmount,
							hidePrice: false
						}
					},
					false,
					'RESET_ALL_TRANSFER_INFO'
				)
			},
			updateActualTransferCreation: (transfer) => {
				set(
					(state) => ({
						actualTransferCreation: {
							...state.actualTransferCreation,
							...transfer
						}
					}),
					false,
					'UPDATE_ACTUAL_TRANSFER_CREATION'
				)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'transfers store'
		}
	)
)
