import { BellSimpleIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { FORM_URL, TRANSFER_FOMENTO_URL } from 'constants/routes'
import useToast from 'hooks/useToast'
import { Fomento } from 'models/Fomento'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorResponse, useNavigate } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'

interface Props {
	transferId: number
	fomento?: Fomento
}

const FomentoButton: React.FC<Props> = (props) => {
	const { transferId, fomento } = props
	const { showBackendErrorToast } = useToast()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)

	const fomentoState = useFomentoStore()
	const FOMENTO_STATUS_URL = `${TRANSFER_FOMENTO_URL}/${transferId}`

	const communicate = async () => {
		setIsLoading(true)
		try {
			await fomentoState.communicate(transferId)
			navigate(FOMENTO_STATUS_URL)
		} catch (err) {
			const error = err as ErrorResponse
			const errorCode = error.status
			showBackendErrorToast(err)
			if (errorCode === 400) {
				await fomentoState.fetchFomentoInfoByBookingId(transferId)
				navigate(`${TRANSFER_FOMENTO_URL}/${transferId}${FORM_URL}`)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const confirm = async () => {
		setIsLoading(true)
		try {
			await fomentoState.confirm(transferId)
			navigate(FOMENTO_STATUS_URL)
		} catch (err) {
			showBackendErrorToast(err)
		} finally {
			setIsLoading(false)
		}
	}

	if (!fomento) {
		return (
			<ButtonComponent
				className='transfer-details-footer-communicate-button'
				variant='secondary'
				onClick={communicate}
				leftIcon={<BellSimpleIcon />}
				loading={isLoading}
				fullWidth>
				{t('transfer.communicate')}
			</ButtonComponent>
		)
	}

	if (fomento && !fomento.confirmedId && !fomento.cancelledAt) {
		return (
			<ButtonComponent
				className='transfer-details-footer-communicate-button'
				variant='secondary'
				onClick={confirm}
				loading={isLoading}
				leftIcon={<BellSimpleIcon />}
				fullWidth>
				{t('transfer.confirm')}
			</ButtonComponent>
		)
	}
}

export default FomentoButton
