import { FetchResponse, FetchResponsePaginated } from 'models/CustomFetch'
import { WorkLog, WorkLogExport, WorkLogFilters, WorkLogPost } from 'models/WorkLog'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const createWorkLogService = async (workLog: WorkLogPost): Promise<WorkLog> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<WorkLog>>(`${endpoints.workLogs}`, {
			method: 'POST',
			token,
			bodyReq: workLog
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getWorkLogService = async (
	page = 1,
	perPage = 10,
	filters: Partial<WorkLogFilters> = {}
) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponsePaginated<WorkLog[]>>(
			`${endpoints.filterWorkLogs}`,
			{
				method: 'POST',
				token,
				bodyReq: {
					startDate: filters.startDate,
					endDate: filters.endDate,
					employeeId: filters.employeeId,
					excludedEmployeeId: filters.excludedEmployeeId,
					page,
					perPage
				}
			}
		)

		if ('error' in response) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getWorkLogByDateService = async (
	date: WorkLogFilters['startDate'] | WorkLogFilters['endDate'],
	employeeId: WorkLogFilters['employeeId']
) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponsePaginated<WorkLog[]>>(
			`${endpoints.filterWorkLogs}`,
			{
				method: 'POST',
				token,
				bodyReq: {
					startDate: date,
					endDate: date,
					employeeId
				}
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateWorkLogByIdService = async (
	id: WorkLog['id'],
	workLog: Partial<WorkLogPost>
): Promise<WorkLog> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<WorkLog>>(`${endpoints.workLogs}/${id}`, {
			method: 'PATCH',
			token,
			bodyReq: workLog
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteWorkLogByIdService = async (id: WorkLog['id']): Promise<WorkLog> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<WorkLog>>(`${endpoints.workLogs}/${id}`, {
			method: 'DELETE',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const exportWorkLogXlsService = async (filter: WorkLogExport) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<string>>(`${endpoints.workLogs}/export-xls`, {
			method: 'POST',
			token,
			bodyReq: { ...filter }
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const exportWorkLogPdfService = async (filter: WorkLogExport) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<string>>(`${endpoints.workLogs}/export-pdf`, {
			method: 'POST',
			token,
			bodyReq: { ...filter }
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
