import FileSearch from 'assets/svgs/FileSearch'
import NotePencilIcon from 'assets/svgs/NotePencilIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import ThreeDotsModal from 'components/elements/ThreeDotsModal/ThreeDotsModal'
import { BILLING_PDF_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	invoiceId: string
}

const BillingModal = ({ isOpen, setIsOpen, invoiceId }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const { setPreviousRoute } = usePreviousRouteStore()

	const menuItems = useMemo(
		() => [
			{
				label: t('settings.billing.see_pdf'),
				href: '#',
				icon: <FileSearch />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(BILLING_PDF_URL + `/${invoiceId}`)
				}
			},
			{
				label: t('settings.billing.edit_invoice'),
				href: '#',
				icon: <NotePencilIcon />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
				}
			},
			{
				label: t('settings.billing.delete_invoice'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal
			}
		],
		[invoiceId]
	)

	return (
		<>
			<ThreeDotsModal isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} />
			<ModalActions
				type='error'
				title={t('settings.billing.delete_invoice_modal_title')}
				description={t('settings.billing.delete_invoice_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={(e) => {
					e!.stopPropagation()
					toggleDeleteModal()
				}}
				primaryButton={{
					text: t('general.remove'),
					onClick: (e) => {
						e!.stopPropagation()
					}
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: (e) => {
						e!.stopPropagation()
						toggleDeleteModal()
					}
				}}
			/>
		</>
	)
}

export default BillingModal
