import { BillIcon, CarIcon, ConfigIcon, MagnifyingGlassIcon, ResourcesIcon } from 'assets/svgs'
import BellIcon from 'assets/svgs/BellIcon'
import CarShareIcon from 'assets/svgs/CarShareIcon'
import ClockUserIcon from 'assets/svgs/ClockUserIcon'
import HamburgerIcon from 'assets/svgs/HamburgerIcon'
import SignOutIcon from 'assets/svgs/SignOutIcon'
import StatsIcon from 'assets/svgs/StatsIcon'
import ButtonComponent from 'components/elements/Button/Button'
import colors from 'constants/colors'
import {
	DAILY_URL,
	DASHBOARD_URL,
	HISTORICAL_URL,
	LOGOUT_URL,
	NOTIFICATIONS_URL,
	RESOURCES_URL,
	SEARCH_URL,
	SETTINGS_URL,
	TRANSFER_REQUESTS_URL,
	WORK_LOG_URL
} from 'constants/routes'
import useNotifications from 'hooks/useNotification'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import DrawerMenu from '../DrawerMenu/DrawerMenu'
import './HeaderWithNotificationBell.css'

interface Props {
	title: string
	subtitle?: string
}

const HeaderWithNotificationBell: React.FC<Props> = (props) => {
	const { title, subtitle } = props
	const { t } = useTranslation()

	const navigate = useNavigate()
	const { unReadNotifications } = useNotifications()
	const isAdmin = currentUserIsAdmin()

	const [menuOpen, setMenuOpen] = useState(false)

	const menuItems = [
		{
			label: t('routes.diary'),
			href: `${DAILY_URL}`,
			icon: <CarIcon color={colors.blueMt} />
		},
		{
			label: t('transfer_share.shared_transfers'),
			href: `${TRANSFER_REQUESTS_URL}`,
			icon: <CarShareIcon color={colors.blueMt} />,
			skip: !isAdmin
		},
		{
			label: t('routes.historical'),
			href: `${HISTORICAL_URL}`,
			icon: <BillIcon color={colors.blueMt} />
		},
		{
			label: t('dashboard.title'),
			href: `${DASHBOARD_URL}`,
			icon: <StatsIcon color={colors.blueMt} />,
			skip: !isAdmin
		},
		{
			label: t('routes.resources'),
			href: `${RESOURCES_URL}`,
			icon: <ResourcesIcon color={colors.blueMt} />,
			skip: !isAdmin
		},
		{
			label: t('work-log.title'),
			href: `${WORK_LOG_URL}`,
			icon: <ClockUserIcon color={colors.blueMt} />
		},
		{
			label: t('routes.configuration_long'),
			href: `${SETTINGS_URL}`,
			icon: <ConfigIcon color={colors.blueMt} />
		},
		{
			label: t('iam.logout'),
			href: `${LOGOUT_URL}`,
			icon: <SignOutIcon />,
			itemFooter: true,
			hasRedColor: true
		}
	]

	return (
		<header className={`${subtitle ? ' hasSubtitle' : emptyString}`}>
			<div className={`header-with-notification-bell`}>
				<div className='header-with-notification-bell-wrapper'>
					<h1>{title}</h1>
				</div>
				<div className='button-container'>
					{isAdmin && (
						<ButtonComponent
							variant='only-icon'
							icon={
								<div className='magnifying-glass-icon-wrapper '>
									<MagnifyingGlassIcon width={25} height={25} />
								</div>
							}
							onClick={() => navigate(SEARCH_URL)}
						/>
					)}
					<ButtonComponent
						variant='only-icon'
						icon={
							<div className='bell-icon-wrapper'>
								<BellIcon />
								{unReadNotifications?.length > 0 && (
									<span className='notification-badge'>{unReadNotifications?.length}</span>
								)}
							</div>
						}
						onClick={() => navigate(NOTIFICATIONS_URL)}
					/>
					<ButtonComponent
						variant='only-icon'
						icon={
							<div className='hamburger-icon-wrapper'>
								<HamburgerIcon />
							</div>
						}
						onClick={() => setMenuOpen(true)}
					/>
				</div>
			</div>
			{subtitle && <h2>{subtitle}</h2>}
			<DrawerMenu
				isOpen={menuOpen}
				onClose={() => setMenuOpen(false)}
				items={menuItems}
				isLightMenu={true}
			/>
		</header>
	)
}

export default HeaderWithNotificationBell
