import { useQuery } from '@tanstack/react-query'
import { workLogsQueryKey } from 'constants/reactQueryKeys'
import { User } from 'models/User'
import { WorkLogFilters } from 'models/WorkLog'
import { useState } from 'react'
import { getWorkLogService } from 'services/workLog'

const useWorkLogs = (
	initialPage = 1,
	perPage = 10,
	employeeId?: User['id'],
	excludedEmployeeId?: User['id']
) => {
	const [page, setPage] = useState(initialPage)
	const [isFiltering, setIsFiltering] = useState(false)
	const [filters, setFilters] = useState<Partial<WorkLogFilters>>({})

	const { data, isLoading, error } = useQuery({
		queryKey: [workLogsQueryKey, page, perPage, { ...filters, employeeId, excludedEmployeeId }],
		queryFn: () => getWorkLogService(page, perPage, { ...filters, employeeId, excludedEmployeeId }),
		placeholderData: (previousData) => previousData,
		staleTime: 60000 * 15, // 15 minutes
		refetchOnWindowFocus: 'always'
	})

	const workLogs = data?.data || []
	const pager = data?.pager || { totalPages: 0 }

	const updateFilters = (newFilters: Partial<WorkLogFilters>) => {
		setIsFiltering(true)
		setFilters((prev) => ({ ...prev, ...newFilters }))
		setPage(1)
	}

	const resetFilters = () => {
		setIsFiltering(false)
		setFilters({})
		setPage(1)
	}

	return {
		workLogs,
		pager,
		page,
		isLoading,
		error,
		filters,
		isFiltering,
		setPage,
		updateFilters,
		resetFilters
	}
}

export default useWorkLogs
