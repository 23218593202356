import { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { useTranslation } from 'react-i18next'
import { monthAndYear, weekName } from 'utils/dateUtils'
import './DayPickerComponent.css'

interface DayPickerComponentProps {
	selected?: Date | undefined
	onSelect: (day?: Date) => void
	onClose?: () => void
	onMonthChange?: (month: Date) => void
	modifiers?: Record<string, (date: Date) => boolean>
	modifiersClassNames?: Record<string, string>
}

const DayPickerComponent = ({
	selected,
	onSelect,
	onClose,
	onMonthChange,
	modifiers = {},
	modifiersClassNames = {}
}: DayPickerComponentProps) => {
	const { t } = useTranslation()
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(selected)
	const [currentMonth, setCurrentMonth] = useState<Date>(selected || new Date())

	useEffect(() => {
		setSelectedDate(selected)
	}, [selected])

	const handleDateSelect = (day: Date | undefined) => {
		setSelectedDate(day)
		onSelect(day)
	}

	const handleMonthChange = (month: Date) => {
		setCurrentMonth(month)
		if (onMonthChange) {
			onMonthChange(month)
		}
	}

	const handleTodayClick = () => {
		const today = new Date()
		setSelectedDate(today)
		onSelect(today)
		setCurrentMonth(today)
		if (onMonthChange) {
			onMonthChange(today)
		}
	}

	const handleClearClick = () => {
		setSelectedDate(undefined)
		onSelect(undefined)
	}

	const handleCloseClick = () => {
		if (onClose) onClose()
	}

	return (
		<div className='dayPicker-container'>
			<DayPicker
				data-testid='DayPicker'
				formatters={{
					formatWeekdayName: (weekDay) => weekName(weekDay),
					formatCaption: (month) => monthAndYear(month)
				}}
				mode='single'
				selected={selectedDate}
				onSelect={handleDateSelect}
				onMonthChange={handleMonthChange}
				captionLayout='buttons'
				className='dayPicker'
				month={currentMonth}
				weekStartsOn={1}
				modifiers={modifiers}
				modifiersClassNames={modifiersClassNames}
			/>
			<div className='dayPicker-legend'>
				<div className='legend-today' onClick={handleTodayClick}>
					<p className='text'>{t('general.today')}</p>
				</div>
				<div className='legend-delete' onClick={handleClearClick}>
					<div className='icon' />
					<p className='text'>{t('general.delete')}</p>
				</div>
				<div className='legend-close' onClick={handleCloseClick}>
					<div className='icon' />
					<p className='text'>{t('general.close')}</p>
				</div>
			</div>
		</div>
	)
}

export default DayPickerComponent
