import Alert from 'components/elements/Alert/Alert'
import { TRANSFER_WITHOUT_REVISION_URL } from 'constants/routes'
import useWithOutRevisionTransfers from 'hooks/useWithOutRevisionTransfers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'

const TransfersWithOutRevisionAlertComponent = () => {
	const [message, setMessage] = useState<string>(emptyString)
	const navigate = useNavigate()
	const { transfersWithOutRevision } = useWithOutRevisionTransfers()
	const { t } = useTranslation()

	useEffect(() => {
		if (!transfersWithOutRevision.length) return
		const message =
			transfersWithOutRevision.length === 1
				? t('with_out_revision_transfer.alert_one')
				: t('with_out_revision_transfer.alert_multiple', { count: transfersWithOutRevision.length })
		setMessage(message)
	}, [transfersWithOutRevision])

	if (!transfersWithOutRevision.length) return null

	const redirectTo = () => {
		navigate(TRANSFER_WITHOUT_REVISION_URL)
	}

	return <Alert message={message} type='info' onClick={redirectTo} />
}

export default TransfersWithOutRevisionAlertComponent
