import DailyTransferCard from 'components/elements/DailyTransferCard/DailyTransferCard'
import { Fomento } from 'models/Fomento'
import { SharedTransfer, StartEndTransfer, TransferReview, TransferView } from 'models/Transfer'
import { FC, useEffect } from 'react'
import './TransferList.css'

interface TransfersListProps {
	transfers: TransferView[]
	fomento?: Fomento[]
	startEndTransfers?: StartEndTransfer[]
	sharedTransfers?: SharedTransfer[]
	transferReviews?: TransferReview[]
	isExpanding?: boolean
}

const TransfersList: FC<TransfersListProps> = ({
	transfers,
	fomento,
	startEndTransfers,
	sharedTransfers,
	transferReviews,
	isExpanding
}) => {
	useEffect(() => {
		const transferId = window.location.hash.replace('#', '')
		if (transferId) {
			setTimeout(() => {
				const element = document.getElementById(`transfer-${transferId}`)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'start' })
				}
			}, 100)
		}
	}, [])

	return (
		<div className='daily-transfer-cards'>
			{transfers.map((transfer) => {
				const fomentoByTransfer = fomento?.find(
					(f) => f.bookingId === transfer.id && f.plate === transfer.licensePlate
				)
				const startEndTransfersByTransfer = startEndTransfers?.filter(
					(startEnd) => startEnd.bookingId === transfer.id
				)
				const sharedTransfersByTransfer = sharedTransfers?.filter(
					(shared) => shared.bookingId === transfer.id
				)

				return (
					<div key={transfer.id} id={`transfer-${transfer.id}`}>
						<DailyTransferCard
							dailyTransfer={transfer}
							fomento={fomentoByTransfer}
							startEndTransfers={startEndTransfersByTransfer}
							sharedTransfers={sharedTransfersByTransfer}
							transferReviews={transferReviews}
							isExpanding={isExpanding}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default TransfersList
