import BellSimpleIcon from 'assets/svgs/BellSimpleIcon'
import CopyIcon from 'assets/svgs/CopyIcon'
import CreateTransfer from 'assets/svgs/CreateTransfer'
import FileSearch from 'assets/svgs/FileSearch'
import FrameCornersIcon from 'assets/svgs/FrameCornersIcon'
import NotePencilIcon from 'assets/svgs/NotePencilIcon'
import ShareFatIcon from 'assets/svgs/ShareFatIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import TransferPoster from 'components/Transfer/TransferPoster'
import {
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_SHARE_URL,
	TRANSFER_URL
} from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useDrawerMenu from 'hooks/useDrawerMenu'
import { useModal } from 'hooks/useModal'
import { Permissions } from 'models/Configurations'
import { TransferView } from 'models/Transfer'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { currentUserIsAdmin } from 'utils/currentUser'
import ModalActions from '../Modal/ModalActions/ModalActions'
import ThreeDotsModal from '../ThreeDotsModal/ThreeDotsModal'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	transfer: TransferView
}

const HeaderTransferModal = ({ isOpen, setIsOpen, transfer }: Props) => {
	const transferId = transfer.id

	const { t } = useTranslation()
	const navigate = useNavigate()
	const { isConfigurationActive } = useConfigurations()
	const [isPosterVisible, togglePosterModal] = useModal()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const isAdmin = currentUserIsAdmin()
	const { setPreviousRoute } = usePreviousRouteStore()
	const {
		copyToClipboard,
		navigateToDuplicateTransfer,
		sendToDriver,
		deleteTransferById,
		loading
	} = useDrawerMenu({
		transfer,
		onClose: () => setIsOpen(false)
	})

	const menuItems = useMemo(
		() => [
			{
				label: t('transfer.details.drawer_menu.see_document'),
				href: '#',
				icon: <FileSearch />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_PDF_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.fomento'),
				href: '#',
				icon: <BellSimpleIcon />,
				skip: !isConfigurationActive(Permissions.NOTIFICATION_FOMENTO),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_FOMENTO_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.show_poster'),
				href: '#',
				icon: <FrameCornersIcon />,
				onClick: togglePosterModal
			},
			{
				label: t('transfer.details.drawer_menu.edit_transfer'),
				href: '#',
				icon: <NotePencilIcon />,
				skip:
					!isConfigurationActive(Permissions.CREATE_TRANSFER) &&
					!isConfigurationActive(Permissions.EDIT_COMMISSION_PAYMENT),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_URL}/${transferId}/edit`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.copy_to_clipboard'),
				href: '#',
				icon: <CopyIcon />,
				onClick: copyToClipboard
			},
			{
				label: t('transfer.details.drawer_menu.send_to_friend'),
				href: `${TRANSFER_SHARE_URL}/${transferId}`,
				icon: <ShareFatIcon />,
				skip: !isAdmin,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_SHARE_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.send_to_driver'),
				href: '#',
				icon: <ShareFatIcon />,
				onClick: sendToDriver,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.duplicate_transfer'),
				href: '#',
				icon: <CreateTransfer />,
				skip: !isConfigurationActive(Permissions.DUPLICATE_TRANSFER),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigateToDuplicateTransfer()
				}
			},
			{
				label: t('transfer.details.drawer_menu.delete_transfer'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal,
				skip: !isAdmin
			}
		],
		[transferId]
	)

	return (
		<>
			<ThreeDotsModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				menuItems={menuItems}
				loading={loading}
			/>
			<TransferPoster
				isVisible={isPosterVisible}
				toggleVisibility={(e?: React.MouseEvent) => {
					e?.stopPropagation()
					togglePosterModal()
				}}
				transfer={transfer}
			/>
			<ModalActions
				type='error'
				title={t('transfer.details.drawer_menu.delete_transfer_modal_title')}
				description={t('transfer.details.drawer_menu.delete_transfer_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={(e?: React.MouseEvent) => {
					e?.stopPropagation()
					toggleDeleteModal()
				}}
				primaryButton={{
					text: t('general.remove'),
					onClick: (e?: React.MouseEvent) => {
						e?.stopPropagation()
						deleteTransferById()
					}
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: (e?: React.MouseEvent) => {
						e?.stopPropagation()
						toggleDeleteModal()
					}
				}}
			/>
		</>
	)
}

export default HeaderTransferModal
