import NoTransfers from 'components/Daily/NoTransfers'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import Pagination from 'components/elements/Pagination/Pagination'
import SmallTransferCard from 'components/elements/SmallTransferCard/SmallTransferCard'
import { SETTINGS_URL, TRANSFER_URL } from 'constants/routes'

import useWithOutRevisionTransfers from 'hooks/useWithOutRevisionTransfers'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { TransferView } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const WithOutRevisionTransfers = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { transfersWithOutRevision, pager, page, isLoading, setPage } = useWithOutRevisionTransfers(
		1,
		10
	)

	const handleClick = (transferId: TransferView['id']) => {
		navigate(`${TRANSFER_URL}/${transferId}?markAsReviewedAdmin=true`)
	}

	if (isLoading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('with_out_revision_transfer.title')}
			leftButton={<ButtonBack url={SETTINGS_URL} />}>
			{transfersWithOutRevision.length > 0 ? (
				<FormSectionLayout>
					{transfersWithOutRevision.map((transfer) => (
						<SmallTransferCard
							key={transfer.id}
							transfer={transfer}
							onClick={() => handleClick(transfer.id)}
						/>
					))}

					<Pagination page={page} totalPages={pager.totalPages} onPageChange={setPage} />
				</FormSectionLayout>
			) : (
				<NoTransfers messageKey={t('with_out_revision_transfer.no_items')} marginTop />
			)}
		</FormLayout>
	)
}

export default WithOutRevisionTransfers
