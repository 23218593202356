import { AirplaneTakeoffIcon, MapPinIcon, MapPinLineIcon, UserFilledIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import RequestShareTag from 'components/elements/CategoryTag/RequestShareTag'
import SharedTransferTag from 'components/elements/CategoryTag/SharedTransferTag'
import StartEndCategoryTag from 'components/elements/CategoryTag/StartEndCategoryTag'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import colors from 'constants/colors'
import { CURRENCY_SYMBOL, NO_VALUE } from 'constants/common'
import { TRANSFER_URL } from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useRouteColors from 'hooks/useRouteColors'
import { Permissions } from 'models/Configurations'
import { Fomento } from 'models/Fomento'
import {
	SharedTransfer,
	StartEndTransfer,
	TransferReview,
	TransferView,
	TypeStartEndTransfer
} from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { useUserStore } from 'stores/useUserStore'
import { emptyString, firstElement, zeroElements } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import ExtraSeatCategoryTag from '../CategoryTag/ExtraSeatCategoryTag'
import FomentoCategoryTag from '../CategoryTag/FomentoCategoryTag'
import TransferReviewedTag from '../CategoryTag/TransferReviewedTag'
import DetailsCard from '../DetailsCard/DetailsCard'
import DetailsCardItem from '../DetailsCard/DetailsCardItem'
import DetailsCardRow from '../DetailsCard/DetailsCardRow'
import PaidTag from '../PaidTag/PaidTag'
import './DailyTransferCard.css'

interface Props {
	dailyTransfer: TransferView
	fomento?: Fomento
	startEndTransfers?: StartEndTransfer[]
	sharedTransfers?: SharedTransfer[]
	transferReviews?: TransferReview[]
	showPaymentData?: boolean
	isExpanding?: boolean
}

const DailyTransferCard = ({
	dailyTransfer,
	fomento,
	startEndTransfers,
	sharedTransfers,
	transferReviews = [],
	showPaymentData,
	isExpanding = false
}: Props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { setPreviousRoute } = usePreviousRouteStore()
	const [hasStart, setHasStart] = useState<boolean>(false)
	const [hasEnd, setHasEnd] = useState<boolean>(false)
	const [accepted, setAccepted] = useState<SharedTransfer>()
	const [pendingTransfers, setPendingTransfers] = useState<SharedTransfer[]>([])
	const { isConfigurationActive } = useConfigurations()

	const userStore = useUserStore()
	const currentUser = userStore.currentUser
	const isAdmin = currentUserIsAdmin()

	const { getRouteColor, getDegradedColor } = useRouteColors()

	const transferReviewsFiltered = transferReviews.filter(
		(review) => review.bookingId === dailyTransfer.id
	)

	const hasExtrasData =
		hasStart || hasEnd || accepted || pendingTransfers.length || transferReviewsFiltered.length

	useEffect(() => {
		if (!startEndTransfers) return
		const startEndByDriverId = startEndTransfers?.filter((startEndTransfer) => {
			if (isAdmin) {
				return startEndTransfer.driverId === dailyTransfer.driverId
			}
			return (
				startEndTransfer.userId === currentUser?.id &&
				dailyTransfer.driverId === startEndTransfer?.driverId
			)
		})

		if (startEndByDriverId.length) {
			const firstTransfer = startEndByDriverId[firstElement]
			setHasStart(firstTransfer.type === TypeStartEndTransfer.start)
			setHasEnd(firstTransfer.type === TypeStartEndTransfer.end)
		}
	}, [startEndTransfers])

	useEffect(() => {
		if (!sharedTransfers) return
		const acceptedTransfer = sharedTransfers.find((transfer) => transfer.acceptedTime !== null)
		if (acceptedTransfer) {
			setAccepted(acceptedTransfer)
		}

		const pendingTransfers = sharedTransfers.filter(
			(transfer) => transfer.acceptedTime === null && transfer.rejectedTime === null
		)
		setPendingTransfers(pendingTransfers)
	}, [sharedTransfers])

	if (!dailyTransfer) return null

	const redirectToTransferDetails = () => {
		setPreviousRoute(`${window.location.pathname}#${dailyTransfer.id}`)
		navigate(`${TRANSFER_URL}/${dailyTransfer.id}`)
	}

	const hasExtras =
		dailyTransfer.babySeats > zeroElements ||
		dailyTransfer.boosterSeats > zeroElements ||
		dailyTransfer.maxicosiSeats > zeroElements

	const isFailedTransfer = dailyTransfer.statusId === 3

	return (
		<Card
			role='button'
			className='daily-transfer-card'
			onClick={() => redirectToTransferDetails()}
			style={{
				borderColor: isFailedTransfer ? colors.cancelled : getRouteColor(dailyTransfer.route)
			}}>
			<HeaderTransferDetails
				transfer={dailyTransfer}
				driverId={dailyTransfer.driverId}
				driver={dailyTransfer.driver}
				time={timeToSpanish(dailyTransfer.time)}
				date={dateToSpanish(new Date(dailyTransfer.date))}
				backgroundColor={
					isFailedTransfer ? colors.cancelledLight : getDegradedColor(dailyTransfer.route)
				}
				showMenuDots
			/>

			<main className='daily-transfer-card-main'>
				<div className='daily-transfer-card-main-item'>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span
							className={`daily-transfer-card-main-item-value ${
								isExpanding ? 'expanded' : emptyString
							}`}>
							{dailyTransfer.originAddress}
						</span>
					</div>
				</div>
				<div className='daily-transfer-card-main-item'>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span
							className={`daily-transfer-card-main-item-value ${
								isExpanding ? 'expanded' : emptyString
							}`}>
							{dailyTransfer.destinationAddress}
						</span>
					</div>
				</div>

				{isExpanding && (
					<div className='daily-transfer-card-main-item'>
						{dailyTransfer.flight && (
							<div className='daily-transfer-card-main-item-wrapper flight-icon'>
								<AirplaneTakeoffIcon width={18} height={18} />
								<span className='daily-transfer-card-main-item-value'>{dailyTransfer.flight}</span>
							</div>
						)}
						<div className='daily-transfer-card-main-item-wrapper'>
							<UserFilledIcon color={colors.black} />
							<span className='daily-transfer-card-main-item-value' data-testid='passengers'>
								{dailyTransfer.passengers}
							</span>
						</div>
						{hasExtras && !showPaymentData && (
							<div className='daily-transfer-card-main-item-wrapper'>
								<ExtraSeatCategoryTag transfer={dailyTransfer} />
							</div>
						)}
					</div>
				)}

				{showPaymentData && (
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.price')}>
								{dailyTransfer.price ? `${dailyTransfer.price} ${CURRENCY_SYMBOL}` : NO_VALUE}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.status')}>
								<PaidTag paid={dailyTransfer.isPaid} />
							</DetailsCardItem>
							{dailyTransfer.paymentMethod && (
								<DetailsCardItem title={t('general.payment_method')}>
									<PaidTag paymentMethod={dailyTransfer.paymentMethod} />
								</DetailsCardItem>
							)}
						</DetailsCardRow>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.toll')}>
								{dailyTransfer.tollWithVat.toFixed(2)} {CURRENCY_SYMBOL}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.status')}>
								{dailyTransfer.commission.toFixed(2)} {CURRENCY_SYMBOL}
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
				)}

				{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) &&
					fomento !== null &&
					!showPaymentData && (
						<div className='daily-transfer-card-main-item'>
							<span className='body2'>{t('fomento.title')}</span>
							<FomentoCategoryTag fomento={fomento} />
						</div>
					)}
			</main>
			{isExpanding && !showPaymentData && hasExtrasData ? (
				<footer className='daily-transfer-card-footer'>
					<span className='body2'>{t('transfer.states')}</span>
					<div className='daily-transfer-card-status-items'>
						<StartEndCategoryTag hasStart={hasStart} hasEnd={hasEnd} small />
						<RequestShareTag pendings={pendingTransfers} small />
						<SharedTransferTag accepted={accepted} small />
						<TransferReviewedTag transferReviews={transferReviewsFiltered} small />
					</div>
				</footer>
			) : null}
		</Card>
	)
}

export default DailyTransferCard
