import DayPickerComponent from 'components/elements/DayPickerComponent/DayPickerComponent'
import { WORK_LOG_HISTORY_URL, WORK_LOG_URL } from 'constants/routes'
import useWorkLogsUtils from 'hooks/useWorkLogsUtils'
import { isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserId } from 'services/utils'
import { dateToSQL } from 'utils/dateUtils'

const WorkLogRegister = () => {
	const navigate = useNavigate()
	const [selected, setSelected] = useState<Date | undefined>(new Date())
	const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
	const [existingWorkLogs, setExistingWorkLogs] = useState<string[]>([])

	const { getWorkLogsByMonth, getUniqueWorkLogDays } = useWorkLogsUtils()

	const loadWorkLogsForMonth = async (month: Date) => {
		try {
			const data = await getWorkLogsByMonth(month, getUserId()!)
			const uniqueDays = getUniqueWorkLogDays(data)
			setExistingWorkLogs(uniqueDays)
		} catch (error) {
			setExistingWorkLogs([])
		}
	}

	useEffect(() => {
		loadWorkLogsForMonth(currentMonth)
	}, [currentMonth])

	const handleDateSelect = (day: Date | undefined) => {
		if (isUndefined(day)) {
			day = selected
		}
		setSelected(day)
		if (day) {
			const formattedDate = dateToSQL(day)
			const dayExists = existingWorkLogs.includes(formattedDate)
			const mode = dayExists ? 'edit' : 'register'

			navigate(`${WORK_LOG_HISTORY_URL}/${formattedDate}/${getUserId()}?${mode}=true`)
		}
	}

	const handleMonthChange = (month: Date) => {
		setCurrentMonth(month)
	}

	return (
		<DayPickerComponent
			selected={selected}
			onSelect={handleDateSelect}
			onClose={() => navigate(WORK_LOG_URL)}
			onMonthChange={handleMonthChange}
			modifiers={{
				hasWorkLog: (date) => existingWorkLogs.includes(dateToSQL(date))
			}}
			modifiersClassNames={{
				hasWorkLog: 'has-worklog'
			}}
		/>
	)
}

export default WorkLogRegister
