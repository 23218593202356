import { ColumnItem } from 'components/elements/DragAndDropColumnList/DragAndDropColumnList'
import ExportModal from 'components/elements/ExportModal/ExportModal'
import { useModal } from 'hooks/useModal'
import useToast from 'hooks/useToast'
import { ExportFormat, HistoricalExportType } from 'models/Elements'
import { TransferFilter } from 'models/Transfer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	exportHistoricalPdfTransfersService,
	exportHistoricalXlsTransfersService
} from 'services/transfers'
import downloadAndOpenFile from 'utils/downloadAndOpenFile'

interface Props {
	isVisible: boolean
	toggleModalVisibility: () => void
	filter: TransferFilter
}

const ExportHistoricalModal: React.FC<Props> = (props) => {
	const { isVisible, toggleModalVisibility, filter } = props
	const [type, setType] = useState<HistoricalExportType>()
	const { showSuccessToast, showErrorToast, showBackendErrorToast } = useToast()
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()
	const [generateDocModalIsVisible, toggleGenerateDocModalVisibility] = useModal()

	const select = (type: HistoricalExportType) => {
		setType(type)
		toggleGenerateDocModalVisibility()
	}

	const handleExport = async (columns: ColumnItem[]) => {
		setLoading(true)

		const columnsSelected = columns.filter((column) => column.selected)

		if (!columnsSelected.length) {
			showErrorToast({
				description: t('historical.export_modal.no_columns_selected'),
				duration: 2000
			})
			return setLoading(false)
		}

		try {
			if (type === ExportFormat.xls) {
				const xls = await exportHistoricalXlsTransfersService(filter, columnsSelected)
				await downloadXls(xls)
			}

			if (type === ExportFormat.pdf) {
				const pdf = await exportHistoricalPdfTransfersService(filter, columnsSelected)
				await downloadPdf(pdf)
			}
			showSuccessToast({
				description: t('historical.export_modal.success'),
				duration: 2000
			})
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			toggleGenerateDocModalVisibility()
			toggleModalVisibility()
		}
	}

	const downloadPdf = async (pdfString: string) => {
		await downloadAndOpenFile({
			fileBase64: pdfString,
			fileName: 'listado_reservas.pdf',
			mimeType: 'application/pdf',
			showErrorToast: showBackendErrorToast
		})
	}

	const downloadXls = async (xmlString: string) => {
		await downloadAndOpenFile({
			fileBase64: xmlString,
			fileName: 'listado_reservas.xls',
			mimeType: 'application/vnd.ms-excel',
			showErrorToast: showBackendErrorToast
		})
	}

	return (
		<ExportModal
			isVisible={isVisible}
			toggleModalVisibility={toggleModalVisibility}
			select={select}
			generateDocModalIsVisible={generateDocModalIsVisible}
			toggleGenerateDocModalVisibility={toggleGenerateDocModalVisibility}
			handleExport={handleExport}
			loading={loading}
			title={t('historical.export_modal.title')}
			xlsButtonText={t('historical.export_modal.xls')}
			pdfButtonText={t('historical.export_modal.pdf')}
			generateButtonText={t('historical.export_modal.generate_document')}
			generateDescriptionText={t('historical.export_modal.description')}
		/>
	)
}

export default ExportHistoricalModal
