import ClockUserIcon from 'assets/svgs/ClockUserIcon'
import EmptyList from 'components/elements/EmptyList/EmptyList'
import Loading from 'components/elements/Loading/Loading'
import Pagination from 'components/elements/Pagination/Pagination'
import { WORK_LOG_HISTORY_URL } from 'constants/routes'
import useWorkLogs from 'hooks/useWorkLogs'
import useWorkLogsUtils from 'hooks/useWorkLogsUtils'
import { User } from 'models/User'
import { GroupedWorkLogs, WorkLogFilters, WorkLogItem } from 'models/WorkLog'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getUserId } from 'services/utils'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { formatDateToSpanish } from 'utils/dateUtils'
import WorkLogModal from './WorkLogModal'
import './WorkLogTable.css'
import WorkLogTableHeader from './WorkLogTableHeader'
import WorkLogTableItem from './WorkLogTableItem'

interface Props {
	noPaginate?: boolean
	perPage?: number
	showOtherUsers?: boolean
	filter?: WorkLogFilters
}

const WorkLogTable = ({
	noPaginate = false,
	perPage = 10,
	showOtherUsers = false,
	filter
}: Props) => {
	const navigate = useNavigate()
	const [selectedDate, setSelectedDate] = useState<WorkLogItem['date'] | null>(null)
	const [selectedUserId, setSelectedUserId] = useState<User['id'] | null>(null)
	const [selectedWorkLogId, setSelectedWorkLogId] = useState<WorkLogItem['id'] | null>(null)
	const [showModal, setShowModal] = useState(false)
	const { setPreviousRoute } = usePreviousRouteStore()
	const { t } = useTranslation()

	const { workLogs, page, pager, setPage, isLoading, updateFilters } = useWorkLogs(
		1,
		perPage,
		!showOtherUsers ? getUserId() : undefined,
		showOtherUsers ? getUserId() : undefined
	)
	const { mapWorkLogsToWorkLogItems } = useWorkLogsUtils()

	const handleDotsClick = (
		date: WorkLogItem['date'],
		userId: WorkLogItem['userId'],
		id: WorkLogItem['id']
	) => {
		setSelectedWorkLogId(id)
		setSelectedDate(date)
		setSelectedUserId(userId)
		setShowModal(true)
	}

	const handleEditClick = () => {
		if (selectedDate && selectedUserId) {
			setPreviousRoute(window.location.pathname)
			navigate(`${WORK_LOG_HISTORY_URL}/${selectedDate}/${selectedUserId}?edit=true`)
		}
	}

	const workLogsCurrentUser = workLogs.filter((workLog) => workLog.userId === getUserId())
	const workLogsOtherUsers = workLogs.filter((workLog) => workLog.userId !== getUserId())
	const workLogsToShow = showOtherUsers ? workLogsOtherUsers : workLogsCurrentUser

	const mappedItems = mapWorkLogsToWorkLogItems(workLogsToShow)
	const sortedItems = [...mappedItems].sort((a, b) => {
		return new Date(b.date).getTime() - new Date(a.date).getTime()
	})
	const groupedContent = useMemo<GroupedWorkLogs>(() => {
		if (!filter) {
			return {
				hasGroups: false,
				items: sortedItems,
				groups: {}
			}
		}
		const groups: Record<string, WorkLogItem[]> = {}
		sortedItems.forEach((item) => {
			if (!groups[item.date]) {
				groups[item.date] = []
			}
			groups[item.date].push(item)
		})

		return {
			hasGroups: true,
			items: [],
			groups
		}
	}, [sortedItems, filter])

	useEffect(() => {
		if (filter) {
			updateFilters(filter)
		}
	}, [filter])

	if (!workLogsToShow.length) {
		return (
			<div className='work-log-table'>
				<div>
					{isLoading ? (
						<Loading hasText={false} />
					) : (
						<EmptyList icon={<ClockUserIcon />} children={t('work-log.no-items')} />
					)}
				</div>
			</div>
		)
	}

	return (
		<>
			<div className='work-log-table'>
				{isLoading ? (
					<div className='work-log-table-loading'>
						<Loading hasText={false} />
					</div>
				) : !groupedContent.hasGroups ? (
					<>
						<WorkLogTableHeader />
						{groupedContent.items.map((item) => (
							<WorkLogTableItem
								showName={!!showOtherUsers}
								key={item.id}
								item={item}
								onDotsClick={() => item.id && handleDotsClick(item.date, item.userId, item.id)}
								onClick={() => {
									setPreviousRoute(window.location.pathname)
									navigate(`${WORK_LOG_HISTORY_URL}/${item.date}/${item.userId}`)
								}}
							/>
						))}
					</>
				) : (
					Object.keys(groupedContent.groups).length > 0 &&
					Object.keys(groupedContent.groups)
						.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
						.map((date) => (
							<div key={date} className='work-log-date-group'>
								<div className='work-log-table-date-header'>
									{formatDateToSpanish(new Date(date))}
								</div>
								<WorkLogTableHeader />
								{groupedContent.groups[date].map((item) => (
									<WorkLogTableItem
										showName={!!showOtherUsers}
										key={item.id}
										item={item}
										onDotsClick={() => item.id && handleDotsClick(item.date, item.userId, item.id)}
										onClick={() => {
											setPreviousRoute(window.location.pathname)
											navigate(`${WORK_LOG_HISTORY_URL}/${item.date}/${item.userId}`)
										}}
									/>
								))}
							</div>
						))
				)}
				{!noPaginate && (
					<Pagination page={page} totalPages={pager.totalPages} onPageChange={setPage} />
				)}
			</div>
			<WorkLogModal
				isOpen={showModal}
				setIsOpen={setShowModal}
				onEditClick={handleEditClick}
				savePreviousRoute={true}
				workLogId={selectedWorkLogId!}
			/>
		</>
	)
}

export default WorkLogTable
